<template>
  <div>
    <div v-if="!$store.state.internetConnection" class="offline-container">
      <CustomIcon fill="#E6653D" name="alert"></CustomIcon> Vous êtes hors ligne
    </div>
    <LoaderContainer></LoaderContainer>
    <router-view class="margin-auto"></router-view>
  </div>
</template>
<script>
import LoaderContainer from '@/components/customContainer/LoaderContainer.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import axios from "axios";
import { setInterval } from 'core-js';
export default {
  name: "App",
  data(){
    return {
    }
  },
  components:{
    LoaderContainer,
    CustomIcon
  },
  methods:{
    testConnection(){
      axios.get('http://localhost:3000/v1/testconnection').then(response=>{
        if(response.status==200){
          this.$store.commit("internetConnection", true)
        }
      }).catch(error=>{
        if (error.code === 'ERR_NETWORK') {
          this.$store.commit("internetConnection", false)
        }
      })
    }
  },
  beforeCreate () {
    this.$store.dispatch('tryAutoLogin')
  },
  mounted(){
    setInterval(()=>{this.testConnection()}, 5000);
  }
}
</script>
<style>
@import url("@/assets/css/style.css");

</style>
