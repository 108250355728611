<template>
  <div>
    <HeaderLoginsPage></HeaderLoginsPage>   
    <div class="signin-container">
      <h1 class="">Connexion</h1>
      <div><input type="text" placeholder="Identifiant" v-model="email" class="underline-input"/></div>
      <div class="error">{{ error.email }}</div>
      <div><input type="password" placeholder="Mot de passe" v-model="password" class="underline-input"/></div>
      <div class="error">{{ error.password }}</div>
      <router-link to="/motdepasse-oublie" class="link-mdp">Mot de passe oublié</router-link>
      <div>
        <button @click="logIn()" class="button-grey mt-10vh">Se connecter</button></div>
    </div>
    <ErrorMessage :msg="$store.state.errorConnecting" v-if="$store.state.errorConnecting" @close="(e)=>$store.commit('thereIsErrorConnecting', null)"></ErrorMessage>
  </div>
</template>
<script>
import HeaderLoginsPage from '@/components/HeaderLoginsPages.vue'
import ErrorMessage from '@/components/customContainer/ErrorMessage.vue'
export default {
  data() {
    return {
      email:null,
      password:null,
      error:{
        email:null,
        password:null,
      }
    };
  },
  components:{
    HeaderLoginsPage,
    ErrorMessage
  },
  methods:{
    logIn(){
      let submit = true
      this.error.email=''
      this.error.password=''
      if(this.email===null){
        submit=false
        this.error.email='Un email est requis'
      }
      if(this.password===null){
        submit=false
        this.error.password='Un mot de passe est requis'
      }
      if(submit){
        this.$store.dispatch('login', {email:this.email, password:this.password})
      }
    }
  }
};
</script>
<style scoped>
.link-mdp{
  margin-top:20px;
  color: #73ACDF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
</style>
