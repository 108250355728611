<template>
  <div class="container">
    <NavBar back="/campagnes"></NavBar>
    <h1 class="text-center">Gérer les tournées</h1>
    <router-link class="button-green" to="/nouvelle-campagne">
      <custom-icon name="itinerary" fill="#fff"></custom-icon>Ajouter une tournée
    </router-link>
    <ListCampaignTemplate :campaignTemplates="listCampaignTemplate" @deleted="(e) => { spliceCampaign(e) }">
    </ListCampaignTemplate>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import ListCampaignTemplate from "@/components/ListCampaignTemplate.vue";
import axios from "@/axios-auth";
export default {
  data() {
    return {
      listCampaignTemplate: null
    }
  },
  components: {
    NavBar,
    CustomIcon,
    ListCampaignTemplate
  },
  methods: {
    getCampaignTemplate() {
      axios.get('/v1/campaigntemplate', {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Content-Type": "application/json",
        }
      }).then((response) => {
        this.listCampaignTemplate = response.data
      }).catch((error) => {
        console.error(error);
      })
    },
    spliceCampaign(uuid) {
      this.listCampaignTemplate.splice(this.listCampaignTemplate.findIndex(element => element.uuid === uuid), 1)
    }
  },
  mounted() {
    this.getCampaignTemplate()
  },
};
</script>
