<template>
  <div class="container">
    <NavBar back="/utilisateurs"></NavBar>
    <h1 class="text-center">Ajouter un nouvel utilisateur</h1>
    <div class="container-60vw">
      <div>
        <label class="label-input">Nom</label>
        <div>
          <input type="text" v-model="nom" class="underline-input w-100" />
        </div>
        <div class="error">{{ errors.nom }}</div>
      </div>
      <div class="mt-20px">
        <label class="label-input">Courriel</label>
        <div>
          <input
            type="text"
            v-model="identifiant"
            class="underline-input w-100"
          />
        </div>
        <div class="error">{{ errors.identifiant }}</div>
      </div>
      <div class="mt-20px">
        <label class="label-input">Mot de passe</label>
        <div>
          <input type="text" v-model="password" class="underline-input w-100" />
        </div>
        <div class="error">{{ errors.password }}</div>
      </div>
      <div class="mt-20px d-flex space-between">
        <router-link to="/utilisateurs" class="button-grey"
          >Annuler</router-link
        >
        <button class="button-grey" @click="createUser()"><span v-if="$route.params.uuid">Modifier</span><span v-else>Ajouter</span></button>
      </div>
    </div>
    <ErrorMessage :msg="$store.state.errorConnecting" v-if="$store.state.errorConnecting" @close="(e)=>$store.commit('thereIsErrorConnecting', null)"></ErrorMessage>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
//import CustomIcon from "@/components/CustomIcon.vue";
import ErrorMessage from "@/components/customContainer/ErrorMessage.vue"
import axios from "@/axios-auth";
export default {
  data() {
    return {
      nom: null,
      identifiant: null,
      password: null,
      isAdmin:false,
      errors: {
        nom: null,
        identifiant: null,
      },
    };
  },
  components: {
    NavBar,
    ErrorMessage
    // CustomIcon,
  },
  methods: {
    createUser() {
      let submit = true;
      this.errors.nom = null;
      this.errors.identifiant = null;
      if (!this.nom) {
        submit = false;
        this.errors.nom = "Vous devez entrer un nom";
      }
      if (!this.identifiant) {
        submit = false;
        this.errors.identifiant = "Vous devez entrer un courriel";
      }
      if (!this.password) {
        submit = false;
        this.errors.password = "Vous devez entrer un mot de passe";
      }
      if (submit) {
        if(this.$route.params.uuid){
          let data = {
            userPID: this.userPID,
            email: this.identifiant,
            isadmin: this.isAdmin,
            username: this.nom,
            password: this.password,
          }
          axios.put("/v1/Users/" + this.$route.params.uuid, data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if(response.status===200){
              this.$router.push('/utilisateurs')
            }
          })
          .catch((error) => {
            console.error(error);
          });
        }
        else{
          let data = {
            email: this.identifiant,
            isadmin: this.isAdmin,
            username: this.nom,
            password: this.password,
          }
          axios
          .post("/v1/users", data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if(response.status===201){
              this.$router.push('/utilisateurs')
            }
          })
          .catch((error) => {
            console.error(error);
          });
        }
      }
    },
    getUser(userPID) {
      axios
        .get("/v1/users/" + userPID, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.identifiant=response.data.email
          this.nom=response.data.username
          this.isAdmin=response.data.isadmin
          this.ctrl_DateUpdate = response.data.updatedAt
          this.userPID = response.data.userPID
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    let userPID = this.$route.params.uuid;
    if (userPID) {
      this.getUser(userPID);
    }
  },
};
</script>
