// indexedDB.js
const DB_NAME = "enercycleOdorForm";
const DB_VERSION = 1;
const OBJECT_STORE_NAME = "campaign";
const OBJECT_STORE_NAME_2 = "sample_location";
const OBJECT_STORE_NAME_3 = "sample";
const OBJECT_STORE_NAME_4 = "campaign_template";
let db;

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      reject(`Error opening database: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        db.createObjectStore(OBJECT_STORE_NAME, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME_2)) {
        db.createObjectStore(OBJECT_STORE_NAME_2, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME_3)) {
        db.createObjectStore(OBJECT_STORE_NAME_3, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME_4)) {
        db.createObjectStore(OBJECT_STORE_NAME_4, {
          keyPath: "id",
          autoIncrement: true,
        });
      }
    };
  });
};
const addObjectToStore = (data, objectStoreName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(objectStoreName, "readwrite");
    const objectStore = transaction.objectStore(objectStoreName);
    const request = objectStore.add(data);

    request.onerror = (event) => {
      reject(`Error adding object: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};
const getAllObjectsFromStore = (objectStoreName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(objectStoreName, "readonly");
    const objectStore = transaction.objectStore(objectStoreName);
    const request = objectStore.getAll();

    request.onerror = (event) => {
      reject(`Error getting objects: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};
const SearchObjectFromStore = (
  attributeName,
  attributeValue,
  objectStoreName = "campaign"
) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([objectStoreName], "readonly");
    const objectStore = transaction.objectStore(objectStoreName);

    const cursorRequest = objectStore.openCursor();
    cursorRequest.onsuccess = function (event) {
    const cursor = event.target.result;
      if (cursor) {
        // Check if the current record has the desired value
        if (cursor.value[attributeName] === attributeValue) {
          console.log("Element found:", cursor.value);
        }

        // Move to the next record
        cursor.continue();
      } else {
        console.log("Search complete: Element not found.");
      }
    };

    cursorRequest.onerror = function (event) {
      console.error("Error opening cursor:", event.target.error);
    };

    // Complete the transaction
    transaction.oncomplete = function (event) {
      resolve(event.target.result);
    };

    transaction.onerror = function (event) {
      reject("Transaction failed:", event.target.error);
    };
  });
};
const GetAllObjectsFromStoreFilter = (fieldName, searchValue, objectStoreName = "campaign") => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([objectStoreName], "readonly");
    const objectStore = transaction.objectStore(objectStoreName);

    const matchingObjects = [];
    const cursorRequest = objectStore.openCursor();

    cursorRequest.onsuccess = function (event) {
      const cursor = event.target.result;

      if (cursor) {
        // Check if the current record has the desired value
        if (cursor.value[fieldName] === searchValue) {
          // Collect the matching object
          matchingObjects.push(cursor.value);
        }

        // Move to the next record
        cursor.continue();
      } else {
        // Cursor has iterated through all records
        console.log("Matching objects:", matchingObjects);
      }
    };

    cursorRequest.onerror = function (event) {
      console.error("Error opening cursor:", event.target.error);
    };

    // Complete the transaction
    transaction.oncomplete = function () {
      resolve(matchingObjects)
    };

    transaction.onerror = function (event) {
      reject("Transaction failed:", event.target.error);
    };
  });
};
const updateObjectFromStore = (fieldName, searchValue, objectStoreName = "campaign", propertyToUpdate, newValue )=>{
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([objectStoreName], "readwrite");
    const objectStore = transaction.objectStore(objectStoreName);

    const request = objectStore.openCursor();

    request.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        const record = cursor.value;
        if (record[fieldName] === searchValue) {
          record[propertyToUpdate] = newValue

          // Save the changes
          const updateRequest = cursor.update(record);

          updateRequest.onsuccess = function () {
            resolve(record)
          };
          updateRequest.onerror = function (event) {
            reject(event)
          };
        }
        cursor.continue();
      }
      request.onerror = function (event) {
        console.error("Error opening cursor:", event.target.error);
      };
    }
  })
}
const updateSample = (key, results)=>{
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['sample'], "readwrite");
    const objectStore = transaction.objectStore('sample');
    var getRequest = objectStore.get(key);
    getRequest.onsuccess = function() {
      var myObject = getRequest.result;
      // Modify myObject here
      myObject.time = results.time;
      myObject.detecte = results.detecte;
      myObject.intensity = results.intensity;
      myObject.persistence = results.persistence;
      myObject.assessment = results.assessment;
      myObject.category = results.category;
      myObject.subcategory = results.subcategory;
      myObject.comments = results.comments;
      myObject.lat = results.lat;
      myObject.lng = results.lng;
      myObject.status = results.status;
      myObject.status_reason = results.status_reason;

      // Put the updated object back into the database
      var putRequest = objectStore.put(myObject);
      putRequest.onsuccess = function() {
        resolve("Update was successful!");
      };
      putRequest.onerror = function(e) {
        reject("Error updating:", e.target.error.name);
      };
  };
  })
}
const deleteObjectFromStore = (
  attributeName,
  deleteValue,
  objectStoreName = "campaign"
) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([objectStoreName], "readwrite");
    const objectStore = transaction.objectStore(objectStoreName);

    // Open a cursor to iterate over the object store
    const cursorRequest = objectStore.openCursor();
    cursorRequest.onsuccess = function (event) {
      const cursor = event.target.result;

      if (cursor) {
        // Check if the current record has the value to delete
        if (cursor.value[attributeName] === deleteValue) {
          // Delete the current object
          const deleteRequest = cursor.delete();

          deleteRequest.onsuccess = function () {
            console.log("Element deleted successfully.");
          };

          deleteRequest.onerror = function (event) {
            console.error("Error deleting element:", event.target.error);
          };
        }
        // Move to the next record
        cursor.continue();
      } else {
        console.log("Delete operation complete.");
      }
    };

    cursorRequest.onerror = function (event) {
      console.error("Error opening cursor:", event.target.error);
    };

    // Complete the transaction
    transaction.oncomplete = function (event) {
      resolve(event.target.result);
    };

    transaction.onerror = function (event) {
      reject("Transaction failed:", event.target.error);
    };
  });
};
const clearObjectStore= (objectStoreName = "campaign") =>{
  const transaction = db.transaction([objectStoreName], "readwrite");
  var objectStore = transaction.objectStore(objectStoreName);
  var clearRequest = objectStore.clear();
  clearRequest.onsuccess = function() {
    console.log("Object store cleared successfully");
  };
  clearRequest.onerror = function(event) {
      console.error("Error clearing object store:", event.target.error);
  };
}
export {
  openDB,
  addObjectToStore,
  getAllObjectsFromStore,
  GetAllObjectsFromStoreFilter,
  SearchObjectFromStore,
  deleteObjectFromStore,
  updateObjectFromStore,
  updateSample,
  clearObjectStore
};
