<template>
  <div class="position-relative">
    <div class="bubble-name" @click="menuActive = !menuActive">
      {{ initials }}
    </div>
    <transition>
      <div class="menu" v-if="menuActive">
        <div v-if="admin">
          <router-link to="/utilisateurs" class="menu-item"
            ><CustomIcon name="users"></CustomIcon>Gérer les
            utilisateurs</router-link
          >
        </div>
        <div v-if="admin">
          <router-link
            to="/configurer-les-campagnes"
            class="menu-item"
            v-if="admin"
            ><CustomIcon name="itinerary"></CustomIcon>Gérer les
            tournées</router-link
          >
        </div>
        <div v-if="admin">
          <router-link
            to="/configurer-les-destinataires"
            class="menu-item"
            v-if="admin"
            ><CustomIcon name="send"></CustomIcon>Gérer les
            destinataires</router-link
          >
        </div>
        <div class="menu-item" @click="confirmDeconnection = true">
          <CustomIcon name="signout"></CustomIcon>Se déconnecter
        </div>
      </div>
    </transition>
    <AlertMessage
      v-show="confirmDeconnection"
      title="Déconnexion"
      msg="Êtes vous sure de vouloir vous déconnecter?"
      buttonMsg="Se déconnecter"
      @submit="signout()"
      @cancel="(e) => (confirmDeconnection = e)"
    ></AlertMessage>
  </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import AlertMessage from "@/components/customContainer/AlertMessage.vue";
export default {
  data() {
    return {
      name: localStorage.getItem("userName"),
      initials: null,
      menuActive: false,
      confirmDeconnection: false,
      admin:JSON.parse(localStorage.getItem("isAdmin"))
    };
  },
  components: {
    CustomIcon,
    AlertMessage,
  },
  methods: {
    getInitials() {
      return this.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
    signout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    this.initials = this.getInitials();
  },
};
</script>
<style scoped>
.bubble-name {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: #73acdf;
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
}
.menu {
  border: 1px solid #73acdf;
  padding: 10px;
  position: absolute;
  right: 0;
  margin-top: 5px;
  background-color: white;
}
.menu-item {
  margin-top: 5px;
  color: #313030;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  text-wrap: nowrap;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
