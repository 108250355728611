<template>
  <div class="radio-button-container" :style="{'justify-content':justifyContent}">
    <div
      v-for="(value, index) in values"
      :key="index"
      class="radio-button-custom"
    >
      <div class="radio-button" v-if="value.value === activeValue" :style="{'background': value.color}">
        <div><img :src="value.imgActive" /></div>
        <div>{{ value.titre }}</div>
        </div>
      <div class="radio-button" @click="select(value.value)" v-else>
        <div><img :src="value.img" /></div>
        <div>{{ value.titre }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeValue: null,
    };
  },
  props: {
    values: Array,
    modelValue: String,
    justifyContent:{default:'space-between',
      type:String
    }
  },
  methods: {
    select(value) {
      this.activeValue = value;
      this.$emit('update:modelValue', value)
    },
  },
  watch: {
    modelValue(newVal) {
      this.activeValue = newVal;
    }
  }
};
</script>
<style>
.radio-button-container {
  display: flex;

}
.radio-button-custom {
  width: 30%;
  margin-right:3%;
}
.radio-button {
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  height: 100%;
  width: 100%;
  background: var(--Light-bleu, #f4f8fd);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
