<template>
    <div>
       <HeaderLoginsPage></HeaderLoginsPage>
       <div class="signin-container">
         <h1>Mot de passe oublié</h1>
         <div><input type="text" placeholder="Identifiant" v-model="identifiant" class="underline-input"/></div>
         <div v-if="successIdentifiant" class="success mt-10px text-center">{{ successIdentifiant }}<br/>
            <RouterLink to="/">Retour</RouterLink></div>
         <div class="error">{{ errorIdentifiant }}</div>
         <div><button class="button-grey mt-10vh" @click="sendForgottenPassword()">Envoyer</button></div>
      </div>
    </div>
</template>
<script>
import HeaderLoginsPage from '@/components/HeaderLoginsPages.vue'
import axios from "@/axios-auth";
export default{
    data(){
        return {
            identifiant:'',
            errorIdentifiant:'',
            successIdentifiant:''

        }
    },
    components:{
        HeaderLoginsPage
    },
    methods:{
        sendForgottenPassword(){
            if(this.identifiant){
                this.errorIdentifiant =""
                axios.post("/v1/Restore", {
                email: this.identifiant,
                })
                .then((response) => {
                    console.log(response)
                    if(response.status===200){
                        this.successIdentifiant ='Vérifier vos courriels pour les instructions'
                    }
                    if(response.response.status && response.response.status===400){
                        this.errorIdentifiant ='Entrez un identifiant valide'
                    }
                })
                .catch((error) => {
                   
                    console.error(error)
                });
            }
            else{
                this.errorIdentifiant = 'Vous devez entrer un identifiant'
            }
        }
    }
}
</script>