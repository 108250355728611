<template>
  <div>
    <div class="metric-item">
      <div class="metric-title">
        {{ title }}
      </div>
      <div v-if="value">
        {{ value }}
      </div>
      <div v-else>Non-disponible</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    title: String,
  },
};
</script>
<style scoped>
.metric-item {
  padding-top:3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0px 0px 5px 5px;
  background: var(--Black, #313030);
  color: white;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height:100%;
}
.metric-title {
  color: #73acdf;
}
</style>
