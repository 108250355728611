<template>
    <div class="container">
        <NavBar back="/campagnes"></NavBar>
        <h1 class="text-center">Choisir une tournée</h1>
        <div class="templates-list" v-if="templates.length > 0">
            <div v-for="(template, index) in templates" :key="index">
                <div v-if="template.sample_location && template.sample_location.length>0" class="template-item cursor-pointer" @click="newCampaign(template.uuid)">
                    {{ template.name }}
                </div>
                <div v-else class="template-item">
                    {{ template.name }} <small class="red-text-color">Ajouter des points pour utiliser</small>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="no-data"> Aucune liste d'emplacement a échantillonné n'est disponible</div>
        </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import { jwtDecode } from "jwt-decode";
import axios from "@/axios-auth";
const { v4: uuidv4 } = require("uuid");
//IndexedDB
import { openDB, addObjectToStore, getAllObjectsFromStore, GetAllObjectsFromStoreFilter, clearObjectStore } from "@/indexedDB";
export default {
    data() {
        return {
            templates: [],
            userid: null
        }
    },
    methods: {
        getCampaignTemplate() {
            axios.get('/v1/campaigntemplate', {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                    "Content-Type": "application/json",
                }
            }).then(async (response) => {
                this.storeCampaignTemplateLocally(response.data)
                try {
                    await openDB();
                    this.templates = await getAllObjectsFromStore('campaign_template')
                    for(let i =0; i<this.templates.length; i++){
                        let sample_location = await GetAllObjectsFromStoreFilter("campaignUUID", this.templates[i].uuid, "sample_location");
                        this.templates[i].sample_location = sample_location
                    }
                } catch (error) {
                    console.log(error)
                }
            }).catch(async (error) => {
                this.templates = await getAllObjectsFromStore('campaign_template')
                for(let i =0; i<this.templates.length; i++){
                        let sample_location = await GetAllObjectsFromStoreFilter("campaignUUID", this.templates[i].uuid, "sample_location");
                        this.templates[i].sample_location = sample_location
                    }
                console.error(error);
            })
        },
        newCampaign(template) {
            let newUuid = uuidv4();
            let campaignData = {
                uuid: newUuid,
                user_id: this.userid,
                username: localStorage.getItem("userName"),
                createdAt: new Date(),
                updatedAt: new Date(),
                serverSend: null
            };
            this.addToIndexedDB(campaignData, template, newUuid);
        },
        async addToIndexedDB(data, template, newUuid) {
            try {
                await openDB();
                await addObjectToStore(data, "campaign");
                //Create points
                let sample_location = await GetAllObjectsFromStoreFilter("campaignUUID", template, "sample_location");
                sample_location.forEach(async (element) => {
                    let sample = {
                        uuid: uuidv4(),
                        no: element.no,
                        name: element.name,
                        address: element.address,
                        campaignUUID: newUuid,
                        sampleLocationUUID: element.uuid,
                        time: null,
                        sample_location_lng: element.lng,
                        sample_location_lat: element.lat,
                        lng: null,
                        lat: null,
                        detecte: null,
                        intensity: null,
                        persistence: null,
                        category: null,
                        subcategory: null,
                        assessment: null,
                        comments: null,
                        status: 1,
                        status_reason: null
                    };
                    await addObjectToStore(sample, "sample");
                });
                this.$router.push("/map/" + newUuid);
            } catch (error) {
                console.error(error);
            }
        },
        async storeCampaignTemplateLocally(data) {
            if (data && data.length > 0) {
                await openDB();
                await clearObjectStore('campaign_template')
                await clearObjectStore('sample_location')
                data.forEach(async (element) => {
                    try {
                        let campaign_template = { name: element.name, uuid: element.uuid }
                        await addObjectToStore(campaign_template, "campaign_template");
                    }
                    catch (error) {
                        console.log(error)
                    }
                    element.sample_locations.forEach(async (sample_locations) => {
                        try {
                            sample_locations.campaignUUID = element.uuid
                            await addObjectToStore(sample_locations, "sample_location");
                        }
                        catch (error) {
                            console.log(error)
                        }
                    })
                })
            }
            else {
                if (this.$store.state.internetConnection) {
                    await openDB();
                    await clearObjectStore('campaign_template')
                }
            }
        },
        decodeJWT() {
            try {
                this.userid = jwtDecode(localStorage.getItem('jwtToken')).id;
            } catch (error) {
                console.error('Error decoding JWT:', error);
            }
        }
    },
    components: {
        NavBar
    },
    mounted() {
        this.decodeJWT()
        this.getCampaignTemplate()
    }
}
</script>
<style>
.templates-list {
    margin-top: 20px;
    position: relative;
}

.template-item {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background-color: #edecec;
    align-items: center;
    margin-top: 15px;
    color: #313030;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>