<template>
    <div>
        <div class="email-list" v-if="emails.length > 0">
            <div v-for="(email, index) in emails" :key="index" class="email-item">
                <div>
                    <div class="email-item-name">{{ email.firstName }} {{ email.lastName }}</div>
                    <div class="email-item-email">{{ email.email }}</div>
                </div>
                <div>
                    <div @click="toggleMenu(index)">
                        <CustomIcon name="three-dot"></CustomIcon>
                    </div>
                    <div class="email-list-menu" v-show="activeMenu.includes(index)">
                        <router-link :to="'/destinataire/'+email.uuid" class="d-flex align-center">
                            <CustomIcon name="edit"></CustomIcon>
                            <div>Modifier</div>
                        </router-link>
                        <div class="d-flex align-center" @click="activePID=email.uuid; confirmDelete=true">
                            <CustomIcon name="garbage"></CustomIcon>
                            <div>Supprimer</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="no-data mt-20px">Créer un destinataire pour commencer</div>
        </div>
        <AlertMessage v-show="confirmDelete" title="Supprimer un destinataire" msg="Êtes vous sur de vouloir supprimer ce destinataire?" buttonMsg="Supprimer" @submit="deleteDestinataire(activePID);confirmDelete=!confirmDelete" @cancel="confirmDelete=!confirmDelete"></AlertMessage>
    </div>
</template>
<script>
import CustomIcon from './CustomIcon.vue';
import axios from "@/axios-auth";
import AlertMessage from "@/components/customContainer/AlertMessage.vue"
export default {
    data(){
        return {
            confirmDelete:false,
            activeMenu: []
        }
    },
    methods:{
        toggleMenu(index) {
            if (this.activeMenu.includes(index)) {
                this.activeMenu.splice(this.activeMenu.indexOf(index), 1);
            } else {
                this.activeMenu.push(index);
            }
        },
        deleteDestinataire(uuid){
            
            axios.delete("/v1/email/" + uuid, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                this.$emit('deleted', uuid)
            })
            .catch((error) => {
                console.error(error);
            });
        }
    },
    props: {
        emails: Array
    },
    components:{
        CustomIcon,
        AlertMessage
    }
}
</script>
<style scoped>
.email-list {
    margin-top: 20px;
}

.email-item {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size: 14px;
    background-color: #edecec;
    align-items: center;
    margin-top: 15px;
    position: relative;
}
.email-item-name {
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.email-item-email {
  color: #313030;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.email-list-menu {
  position: absolute;
  background-color: white;
  padding: 10px;
  right: 10px;
  border: 1px solid #00b156;
  font-weight: 700;
  z-index:10000;
}
.email-list-menu > a {
  text-decoration: none;
  color: #313030;
}
</style>