<template>
  <div>
    <div class="geocoder-label">Trouver une adresse</div>
    <div class="position-relative">
      <label class="label-input">Adresse</label>
      <input
        type="text"
        class="underline-input w-100"
        v-model="address"
        @keyup="geocode()"
      />
    </div>
    <div class="position-relative">
      <div class="geocoder-container" v-if="results.length > 0">
        <div
          v-for="(result, index) in results"
          class="geocoder-items"
          :key="index"
          @click="zoomTo(result)"
        >
          <div class="ml-10px">
            <span v-if="result.properties.name">{{ result.properties.name }} &nbsp;</span>
            <span v-if="result.properties.housenumber">{{ result.properties.housenumber }} &nbsp;</span>
            <span v-if="result.properties.street">{{ result.properties.street }} &nbsp;</span>
            <span v-if="result.properties.city">{{ result.properties.city }} &nbsp;</span>
            <span v-if="result.properties.county">{{ result.properties.county }} &nbsp;</span>
            <span v-if="result.properties.country">{{ result.properties.country }} &nbsp;</span>
            <span v-if="result.properties.state">{{ result.properties.state }} &nbsp;</span>
            <span v-if="result.properties.postcode">{{ result.properties.postcode }} &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import maplibregl from "maplibre-gl";
import axios from "axios";
import utils from "@/mixins/utils"
export default {
  data() {
    return {
      address: "",
      results: [],
      position: [45, -73],
      geocodeMarker: null,
      initial:true
    };
  },
  mixins:[utils],
  props: {
    map: Object,
    initialAddress:String,
    lat:Number,
    lng:Number
  },
  methods: {
    geocode() {
      if (this.address) {
        let url =
          "https://photon.komoot.io/api/?q=" +
          this.address +
          "&lat=" +
          this.position[0] +
          "&lon=" +
          this.position[1];
        axios
          .get(url)
          .then((results) => {
            this.results = results.data.features;
          })
          .catch();
      } else {
        this.results = [];
      }
    },
    zoomTo(data, coordinates=undefined) {
      if (data) {
        this.map.flyTo({
          center: data.geometry.coordinates,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
        if (this.geocodeMarker) {
          this.geocodeMarker.remove();
        }
        this.geocodeMarker = new maplibregl.Marker()
          .setLngLat(data.geometry.coordinates)
          .addTo(this.map);
        this.address=this.formatAddress(data)
        this.results=[]
        this.$emit("update:modelValue", data);
      }
      else{
        this.address=''
        let feature = {
          geometry:{
            coordinates:[coordinates.lng, coordinates.lat],
            type:"Point"
          },
          properties:{

          },
          type:"Feature"
        }
        this.$emit("update:modelValue", feature);
      }
    },
    changeMarkerPoisition(e) {
      if (this.geocodeMarker) {
        this.geocodeMarker.remove();
      }
      this.geocodeMarker = new maplibregl.Marker()
        .setLngLat(e.lngLat)
        .addTo(this.map);
      this.reverseGeocoding(e.lngLat);
    },
    reverseGeocoding(coordinates) {
      let adresse =
        "https://photon.komoot.io/reverse?lon=" +
        coordinates.lng +
        "&lat=" +
        coordinates.lat;
      axios
        .get(adresse)
        .then((response) => {
          this.zoomTo(response.data.features[0], coordinates);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getInitialInfo(){
      if (this.geocodeMarker) {
        this.geocodeMarker.remove();
      }
      if(this.initialAddress){
        this.address=this.initialAddress
        this.geocodeMarker = new maplibregl.Marker()
          .setLngLat([this.lng, this.lat])
          .addTo(this.map);
          this.initial=false
      }

    }
  },
  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          (this.position = [
            position.coords.latitude,
            position.coords.longitude,
          ])
      );
    }

  },
  beforeUpdate() {
    if(this.initial){
      this.getInitialInfo()
    }
    this.map.on("click", (e) => {
      this.changeMarkerPoisition(e);
    });
  },
};
</script>
<style scoped>
.geocoder-label {
  font-size: 15px;
  color: white;
  font-weight: 400;
  margin-bottom: 5px;
}
.geocoder-container {
  position: absolute;
  width: 100%;
  background-color: white;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid black;
  z-index: 1000;
}
.magnifying-glass-geocoder {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background-color: #242424;
  border-top-right-radius: 10px;
  padding-right: 10px;
  color: #dfdfdf;
}
.geocoder-container::-webkit-scrollbar {
  width: 7px;
}

.geocoder-container::-webkit-scrollbar-track {
  background-color: white;
}

.geocoder-container::-webkit-scrollbar-thumb {
  background-color: #646872;
}
.geocoder-items {
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.geocoder-items:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
</style>
