<template>
  <div>
    <PwaPrompt class="position-fixed"></PwaPrompt>
    <div
      class="login-header"
      :style="{ backgroundImage: 'url(' + bg_leaf + ')' }"
    >
      <img :src="logo" />
    </div>
    <div class="leaf-container">
      <img :src="eEnercycle" />
    </div>
  </div>
</template>
<script>
import PwaPrompt from "@/components/pwa/PwaPrompt.vue";
import logo from "@/assets/images/enercycle.png";
import bg_leaf from "@/assets/images/bg_leaf.png";
import eEnercycle from "@/assets/images/e-enercycle.png";
export default {
  data() {
    return {
      logo,
      bg_leaf,
      eEnercycle,
    }
  },
  components:{
    PwaPrompt
  },
};
</script>
<style scoped>
.login-header {
  background-color: green;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.leaf-container {
  position: absolute;
  top: 30vh;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
