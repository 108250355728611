<template>
  <div>
    <div class="users-list" v-if="users.length > 0">
      <div v-for="(user, index) in users" :key="index" class="users-item">
        <div class="w-100">
          <div class="users-item-name">{{ user.username }}</div>
          <div class="users-item-email">{{ user.email }}</div>
        </div>
        <div>
          <CustomSelect
            :options="options"
            v-model="user.isadmin"
            class="w-100"
            @update:modelValue="(e)=>{updateRights(user)}"
          ></CustomSelect>
        </div>
        <div>
          <div class="ml-10px" @click="toggleMenu(index)">
            <CustomIcon name="three-dot"></CustomIcon>
          </div>
          <div class="user-list-menu" v-show="activeMenu.includes(index)">
            <router-link
              :to="'/utilisateur/' + user.userPID"
              class="d-flex align-center"
              ><CustomIcon name="edit"></CustomIcon>
              <div>Modifier</div></router-link
            >
            <div class="d-flex align-center" @click="confirmDelete=!confirmDelete; activePID=user.userPID">
              <CustomIcon name="garbage"></CustomIcon>
              <div>Supprimer</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="!$store.state.internetConnection" class="offline-container mt-20px"> Connectez vous à Internet pour gérer les utilisateurs </div>
    <AlertMessage v-show="confirmDelete" title="Supprimer un utilisateur" msg="Êtes vous sur de vouloir supprimer cet utilisateur?" buttonMsg="Supprimer" @submit="deleteUser(activePID);confirmDelete=!confirmDelete" @cancel="confirmDelete=!confirmDelete"></AlertMessage>
  </div>
</template>
<script>
import CustomSelect from "@/components/customForm/CustomSelect.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import axios from "@/axios-auth";
import AlertMessage from "@/components/customContainer/AlertMessage.vue"
export default {
  data() {
    return {
      options: [
        {
          label: "Utilisateur",
          value: false,
        },
        {
          label: "Administrateur",
          value: true,
        },
      ],
      activeMenu: [],
      confirmDelete:false,
      activePID:null
    };
  },
  methods: {
    toggleMenu(index) {
      if (this.activeMenu.includes(index)) {
        this.activeMenu.splice(this.activeMenu.indexOf(index), 1);
      } else {
        this.activeMenu.push(index);
      }
    },
    deleteUser(userPID) {
      axios
        .delete("/v1/users/" + userPID, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$emit('deleted', userPID)
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateRights(user){
      axios.put('/v1/Users/'+user.userPID, user, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        }).then(result=>{
        console.log(result)
      }).catch(error=>{
        console.log(error)
      })
    }
  },
  props: {
    users: {
      default: function () {
        return [];
      },
      type: Array,
    },
  },
  components: {
    CustomSelect,
    CustomIcon,
    AlertMessage
  },
};
</script>
<style>
.users-list {
  margin-top: 20px;
}
.users-item {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #edecec;
  align-items: center;
  margin-top: 15px;
  position: relative;
}
.users-item-name {
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.users-item-email {
  color: #313030;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user-list-menu {
  position: absolute;
  background-color: white;
  padding: 10px;
  right: 10px;
  border: 1px solid #00b156;
  z-index: 5000;
  font-size: 14px;
  font-weight: 700;
}
.user-list-menu > a {
  text-decoration: none;
  color: #313030;
}
</style>
