<!-- PWAPrompt.vue -->
<template>
  <div v-if="shown" class="pwa-prompt-container">
    Ajouter cette application à mon fond d'écran
    <div class="d-flex mt-10px">
      <button @click="installPWA" class="button-green">Installer!</button>

      <button @click="dismissPrompt" class="button-grey ml-10px">Non merci</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },

  methods: {
    dismissPrompt() {
      this.shown = false;
    },

    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    },
  },
};
</script>
<style>
.pwa-prompt-container{
    padding:20px;
    background-color:white;
    width:100%;
}
</style>
