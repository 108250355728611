import { createStore } from "vuex";
import router from "./router/index";
import axios from "@/axios-auth";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      jwtToken: null,
      userName: null,
      isAdmin: false,
      loading: false,
      errorConnecting: "",
      internetConnection:true
    };
  },
  getters: {
    jwtToken: (state) => state.jwtToken,
    userName: (state) => state.userName,
    isAdmin: (state) => state.isAdmin,
    internetConnection: (state)=>state.internetConnection,
    errorConnecting: (state)=>state.errorConnecting
  },
  mutations: {
    jwtToken(state, jwtToken) {
      state.jwtToken = jwtToken;
    },
    nameUser(state, userName) {
      state.userName = userName;
    },
    isAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    clearAuthData(state) {
      state.jwtToken = null;
      state.nameUser = null;
      state.isAdmin = null;
      localStorage.clear("jwtToken");
      localStorage.clear("nameUser");
      localStorage.clear("isAdmin");
      router.push("/");
    },
    isLoading(state) {
      state.loading = true;
    },
    isNotLoading(state) {
      state.loading = false;
    },
    thereIsErrorConnecting(state, msg) {
      state.errorConnecting = msg;
    },
    noErrorConnecting(state) {
      state.errorConnecting = "";
    },
    internetConnection(state, internetStatus){
      state.internetConnection = internetStatus;
    }
  },
  actions: {
    login({ commit }, authUser) {
      //login
      axios
        .post("/v1/authenticate", {
          email: authUser.email,
          password: authUser.password,
        })
        .then((response) => {
          if(response.data){
            commit("jwtToken", response.data.token);
            commit("nameUser", response.data.userName);
            commit("isAdmin", response.data.isAdmin);
            localStorage.setItem("jwtToken", response.data.token);
            localStorage.setItem("userName", response.data.userName);
            localStorage.setItem("isAdmin", response.data.isAdmin);
            router.push("/campagnes");
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    tryAutoLogin({ commit }) {
      const jwtToken=localStorage.getItem("jwtToken");
      const userName=localStorage.getItem("userName");
      const isAdmin=Boolean(localStorage.getItem("isAdmin"));
      if (!jwtToken) {
        return;
      }
      commit("jwtToken", jwtToken);
      commit("nameUser", userName);
      commit("isAdmin", isAdmin);
    },
    logout({ commit }) {
      commit("clearAuthData");
    },
  },
});

export default store;
