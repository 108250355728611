<template>
  <div class="position-relative w-100">
    <div class="mt-20px">
      <h3 class="pl-8px">{{title}}</h3>
    </div>
    <div class="custom-select-box" @click="show = !show">
      <div>{{ label }}</div>
      <div :class="{ rotate: show }">
        <CustomIcon name="chevron-down"></CustomIcon>
      </div>
    </div>
    <div class="custom-select-options" v-if="show">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="custom-select-option"
        @click="selectThis(option.value)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
export default {
  data() {
    return {
      label: null,
      value: null,
      show: false,
    };
  },
  components: {
    CustomIcon,
  },
  props: {
    options: {
      default: function () {
        return [];
      },
      type: Array,
    },
    modelValue: String,
    title:{
      default: "",
      type: String,
    },
  },
  methods: {
    selectThis(value) {
      this.label = this.options.find(
        (element) => element.value === value
      ).label;
      this.value = value;
      this.show = false;
      this.$emit("update:modelValue", value);
    },
    setValue(newVal){
      if (this.options.length > 1) {
      let selectedOption = this.options.find(
        (element) => element.value == newVal
      );
      if (selectedOption) {
        this.label = selectedOption.label;
      }
      this.value = newVal;
    }
    }
  },
  watch: {
    modelValue(newVal) {
      this.setValue(newVal)
    }
  },
  mounted(){
    this.setValue(this.modelValue)
  }
};
</script>
<style scoped>
.custom-select-box {
  display: flex;
  align-items: center;
  border: 2px solid var(--Black, #313030);
  border-radius: 8px;
  padding: 12px 8px 12px 8px;
  background-color: white;
  min-width: 100px;
  justify-content: space-between;
}
.custom-select-options {
  position: absolute;
  background-color: white;
  min-width: 100%;
  z-index: 2000;
  border: 1px solid var(--Black, #313030);
  padding: 10px;
}
.rotate {
  rotate: 180deg;
}
h3 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
