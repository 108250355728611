import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/LogIn.vue";
import ForgottenPassword from "@/views/ForgottenPassword.vue";
import ReinitiatePassword from "@/views/ReinitiatePassword.vue";
import AllCampaign from "@/views/AllCampaign.vue";
import MapCampaign from "@/views/MapCampaign.vue";
import UsersManagement from "@/views/UsersManagement.vue";
import UserManagement from "@/views/UserManagement.vue";
import SamplePoints from "@/views/SamplePoints.vue";
import SamplePoint from "@/views/SamplePoint.vue";
import ConfigureCampaigns from "@/views/ConfigureCampaigns.vue";
import DestinationsManagement from "@/views/DestinationsManagement.vue";
import DestinationManagement from "@/views/DestinationManagement.vue";
import CampaignTemplate from "@/views/CampaignTemplate.vue";
import ChooseTemplate from "@/views/ChooseTemplate.vue";



import PdfPreview from "@/views/PdfPreview.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/campagnes");
      }
    },
  },
  {
    path: "/motdepasse-oublie",
    name: "ForgottenPassword",
    component: ForgottenPassword
  },
  {
    path: "/nouveau-motdepasse/:token",
    name: "ReinitiatePassword",
    component: ReinitiatePassword
  },
  {
    path: "/campagnes",
    name: "AllCampaign",
    component: AllCampaign,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/choisir-la-tournee",
    name: "ChooseTemplate",
    component: ChooseTemplate,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/map/:uuid?",
    name: "Map",
    component: MapCampaign,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/utilisateurs",
    name: "UsersManagement",
    component: UsersManagement,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/utilisateur/:uuid?",
    name: "UserManagement",
    component: UserManagement,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/configurer-les-campagnes",
    name: "ConfigureCampaigns",
    component: ConfigureCampaigns,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/nouvelle-campagne/:uuid?",
    name: "CampaignTemplate",
    component: CampaignTemplate,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/configurer-les-campagnes",
    name: "ConfigureCampaigns",
    component: ConfigureCampaigns,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/points/:uuid",
    name: "SamplePoints",
    component: SamplePoints,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/configurer-les-destinataires",
    name: "DestinationsManagement",
    component: DestinationsManagement,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/destinataire",
    name: "DestinationManagement",
    component: DestinationManagement,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/destinataire/:uuid?",
    name: "DestinationManagement",
    component: DestinationManagement,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/point/:campaignPID/:uuid?",
    name: "SamplePoint",
    component: SamplePoint,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/rapport/:uuid",
    name: "PdfPreview",
    component: PdfPreview,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("jwtToken")) {
        next();
      } else {
        next("/");
      }
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
