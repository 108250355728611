<template>
  <div class="container">
    <NavBar back="/configurer-les-campagnes"></NavBar>
    <h1 class="text-center">Ajouter une tournée</h1>
    <div class="mt-20px">
      <label class="label-input">Nom de la tournée</label>
      <div>
        <input type="text" v-model="name" class="underline-input w-100" />
        <div class="error">{{ error.name }}</div>
      </div>
    </div>
    <div class="mt-20px d-flex space-around">
      <button class="button-grey" @click="saveCampaignTemplate()">Enregistrer</button>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import axios from "@/axios-auth";
export default {
  data() {
    return {
      name: '',
      PID: null,
      error: {
        name: ''
      }
    };
  },
  components: {
    NavBar,
  },
  methods: {
    saveCampaignTemplate() {
      this.error.name = ''
      if (this.name !== '') {
        let data = { name: this.name }
        if (this.PID) {
          axios.put("/v1/campaigntemplate/" + this.PID, data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtToken"),
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (response.status === 200) {
                this.$router.push('/configurer-les-campagnes')
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          axios
            .post("/v1/campaigntemplate", data, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                this.$router.push('/configurer-les-campagnes')
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
      else {
        this.error.name = 'Vous devez donner un nom à votre tournée'
      }
    },
    getSingleCampaignTemplate(PID) {
      axios
        .get("/v1/campaigntemplate/" + PID, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.name = response.data.name
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  mounted() {
    this.PID = this.$route.params.uuid;
    if (this.PID) {
      this.getSingleCampaignTemplate(this.PID);
    }
  }
};
</script>
