<template>
  <div>
    <div class="points-list" v-if="points.length > 0">
      <div v-for="(point, index) in points" :key="index" class="points-item">
        <div>
          {{ point.no }}<br />
          {{ point.name }}
        </div>
        <div>
          <div class="ml-10px" @click="toggleMenu(index)">
            <CustomIcon name="three-dot"></CustomIcon>
          </div>
          <div class="point-list-menu" v-show="activeMenu.includes(index)">
            <router-link
              :to="'/point/' + campaignPID+'/'+point.uuid"
              class="d-flex align-center"
              ><CustomIcon name="edit"></CustomIcon>
              <div>Modifier</div></router-link
            >
            <div class="d-flex align-center">
              <CustomIcon name="garbage"></CustomIcon>
              <div @click="confirmDeletePoint(point.uuid)">Supprimer</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
        <div class="no-data mt-20px">Créer un point pour commencer</div>
    </div>
    <AlertMessage v-show="showDelete" title="Supprimer un point" msg="Êtes vous sur de vouloir supprimer ce point?" buttonMsg="Supprimer" @cancel="showDelete=!showDelete" @submit="deletePoint()"></AlertMessage>
  </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import AlertMessage from "./customContainer/AlertMessage.vue";
import axios from "@/axios-auth"
export default {
  data() {
    return {
      activeMenu: [],
      showDelete:false,
      activeDelete:null
    };
  },
  methods: {
    toggleMenu(index) {
      if (this.activeMenu.includes(index)) {
        this.activeMenu.splice(this.activeMenu.indexOf(index), 1);
      } else {
        this.activeMenu.push(index);
      }
    },
    confirmDeletePoint(uuid){
      this.showDelete=true
      this.activeDelete=uuid
    },
    deletePoint(){
      axios.delete('/v1/samplelocation/'+this.activeDelete,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        }).then((response)=>{
        console.log(response)
      }).catch((error)=>{
        console.error(error)
      })
    }
  },
  props: {
    points: {
      default: function () {
        return [];
      },
      type: Array,
    },
    campaignPID:String
  },
  components: {
    CustomIcon,
    AlertMessage
  },
};
</script>
<style scoped>
.points-list {
  margin-top: 20px;
  position: relative;
}
.points-item {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #edecec;
  align-items: center;
  margin-top: 15px;
  position: relative;
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.point-list-menu {
  position: absolute;
  background-color: white;
  padding: 10px;
  right: 10px;
  border: 1px solid #00b156;
  z-index:10000;
}
.point-list-menu > a {
  text-decoration: none;
  color: #313030;
}
</style>
