<template>
  <div class="d-flex align-top space-between">
    <router-link class="button-green w-100" v-if="campaign" to="/choisir-la-tournee">
      <custom-icon name="plus-circle"></custom-icon>Nouvelle tournée
    </router-link>
    <div v-if="!campaign">
      <router-link :to="back" class="back">
        <custom-icon name="chevron-left"></custom-icon>Annuler</router-link
      >
    </div>
    <menu-admin></menu-admin>
  </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import MenuAdmin from "./MenuAdmin.vue";

export default {
  props: {
    campaign: { default: false, type: Boolean },
    back: { default: "/campagnes", type: String },
  },
  components: {
    CustomIcon,
    MenuAdmin,
  },
  methods: {
   
  },
};
</script>
<style scoped>
.back {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
</style>
