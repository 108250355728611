<template>
    <div>
        <HeaderLoginsPage></HeaderLoginsPage>
        <div class="signin-container">
            <h1>Nouveau mot de passe</h1>
            <div><input type="password" placeholder="Nouveau mot de passe" v-model="nouveauMotDePasse" class="underline-input" />
            </div>
            <div class="mt-10px"><input type="password" placeholder="Confirmer le mot de passe" v-model="confirmationMotDePasse" class="underline-input" />
            </div>
            <div v-if="success" class="success mt-10px text-center">{{ success }}<br/>
            <RouterLink to="/">Retour</RouterLink></div>
            <div v-if="error" class="error mt-10px">{{ error }}</div>
            <div><button class="button-grey mt-10vh" @click="sendNewPassword()">Envoyer</button></div>
        </div>
    </div>
</template>
<script>
import HeaderLoginsPage from '@/components/HeaderLoginsPages.vue'
import axios from "@/axios-auth";
export default {
    data(){
        return {
            nouveauMotDePasse:'',
            confirmationMotDePasse:'',
            success:'',
            error:''
        }
    }, 
    components: {
        HeaderLoginsPage
    },
    methods: {
        sendNewPassword(){
            let token = this.$route.params.token
            this.error=''
            if(this.nouveauMotDePasse==='' || this.confirmationMotDePasse===''){
                this.error='Remplissez bien les deux champs'
            }
            if(this.nouveauMotDePasse!==this.confirmationMotDePasse){
                this.error='Les deux mots de passe ne concordent pas'
            }
            if(this.error===''){
                axios.post("/v1/newpassword/"+token,{password:this.nouveauMotDePasse} ).then(response=>{
                    console.log(response)
                    if(response.response && response.response.status==403){
                       this.error=response.response.data.msg
                    }
                    else if (response.status==500){
                        this.error=response.data.error
                    }
                    else{
                        this.success = response.data.msg  
                    }
                }).catch(
                    error=>{
                        console.log(error)
                    }
                )
            }

        }
    }
}
</script>