<template>
  <div class="container">
    <NavBar back="/configurer-les-campagnes"></NavBar>
    <h1 class="text-center">Gérer les points</h1>
    <router-link class="button-green" :to="'/point/'+this.$route.params.uuid">
      <custom-icon name="marker" fill="#fff"></custom-icon>Ajouter un point
    </router-link>
    <h3>{{ campaign }}</h3>
    <ListPoints :points="points" :campaignPID="this.$route.params.uuid"></ListPoints>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import ListPoints from "@/components/ListPoints.vue";
import axios from "@/axios-auth";
export default {
  data() {
    return {
      campaign:'',
      points: [],
    };
  },
  methods: {
    getDataFromServer(PID) {
      axios
        .get("/v1/campaigntemplate/"+PID, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.campaign=response.data.name
          this.points=response.data.sample_locations
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.PID = this.$route.params.uuid;
    if (this.PID) {
      this.getDataFromServer(this.PID);
    }
 
  },
  components: {
    NavBar,
    CustomIcon,
    ListPoints,
  }
};
</script>
