<template>
    <div class="container">
        <NavBar ></NavBar>
        <h1 class="text-center">Gérer les utilisateurs</h1>
        <router-link class="button-green" to="/utilisateur">
            <custom-icon name="users" fill="#fff"></custom-icon>Ajouter un utilisateur
        </router-link>
        <ListUsers :users="users" @deleted="(e)=>{spliceUser(e)}"></ListUsers>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import CustomIcon from '@/components/CustomIcon.vue';
import ListUsers from '@/components/ListUsers.vue';
import axios from "@/axios-auth";
export default{
    data(){
        return {
            users:[]
        }
    },
    components:{
        NavBar,
        CustomIcon,
        ListUsers
    },
    methods:{
        getAllUsers(){
            axios.get('/v1/users', {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
                  'Content-Type': 'application/json'
                }
              }).then((response)=>{
                this.users=response.data
            }).catch()
        },
        spliceUser(userPid){
            axios.delete('/v1/users/'+userPid).then((response)=>{
                console.log(response)
                this.users.splice(this.users.findIndex(element=>element.userPid===userPid), 1)
            }).catch()    
        }
    },
    mounted(){
        this.getAllUsers()
    }
}
</script>