<template>
    <div>
        <PdfReport></PdfReport>
    </div>
</template>
<script>
import PdfReport from '@/components/PdfReport.vue'
export default {
    components:{
        PdfReport
    }
}
</script>
