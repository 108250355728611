<template>
  <div ref="document" class="container">
    <NavBar></NavBar>
    <div class="container-pdf">
      <div id="element-to-convert" v-if="results">
        <h1 class="text-center">Énercycle</h1>
        <h2 class="text-center">Suivi  d'odeurs et point(s) de mesure</h2>
        <div>
          <p class="text-pdf">Date: <u>{{ makeItTwoDigit(new Date(results.createdAt).getDate()) }}/{{ makeItTwoDigit(new Date(
            results.createdAt).getMonth()+1)}}/{{ new Date(results.createdAt).getFullYear() }} - {{ new Date(results.createdAt).getHours() }}:{{new Date(results.createdAt).getMinutes() }}</u></p>
          <p class="text-pdf">Juré échantillonneur: <u>{{ results.User.username }}</u></p>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th rowspan="2">Points</th>
                <th rowspan="2">Localisation</th>
                <th rowspan="2">Heure</th>
                <th rowspan="1" colspan="7">Intensité</th>
                <th rowspan="1" colspan="1">Persistance/ Intensité</th>
                <th rowspan="2">Description</th>
                <th rowspan="1" colspan="3">Appréciation</th>
                <th rowspan="2">Vents (Vitesse)</th>
                <th rowspan="2">Vents (Direction)</th>
                <th rowspan="2">Commentaires</th>
              </tr>
              <tr>
                <th>
                  <div class="vertical-text" v-html="makeItVertical('Non-détectée')"></div>
                </th>
                <th class="vertical-text" v-html="makeItVertical('Très faible')"></th>
                <th class="vertical-text" v-html="makeItVertical('Faible')"></th>
                <th class="vertical-text" v-html="makeItVertical('Modérée')"></th>
                <th class="vertical-text" v-html="makeItVertical('Forte')"></th>
                <th class="vertical-text" v-html="makeItVertical('Très forte')"></th>
                <th class="vertical-text" v-html="makeItVertical('Ext forte')"></th>
                <th class="vertical-text">
                  <small>C–Continue</small><br/>
                  <small>P-Ponctuelle</small><br/>
                  <small>V-Variable</small><br/>
                </th>
                <th class="vertical-text" v-html="makeItVertical('Agréable')"></th>
                <th class="vertical-text" v-html="makeItVertical('Neutre')"></th>
                <th class="vertical-text" v-html="makeItVertical('Désagréable')"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result, index) in results.samples" :key="index">
                <td> {{ result.sample_location.no }}</td>
                <td>{{ result.sample_location.name }}</td>
                <td v-if="result.status!==1">{{ makeItTwoDigitnew(Date(result.createdAt).getHours()) }}: {{ new Date(
                  result.createdAt).getMinutes()}}</td>
                <td v-else></td>
                <td><span v-if="result.detecte==='Non-détecté'">X</span> </td>
                <td v-for="(option, index) in intensityOptions" :key="index"><span v-if="option===result.intensity">X</span></td>
                <td>{{ result.persistance }}</td>
                <td>{{ result.category }} - {{ result.subcategory }}</td>
                <td v-for="(option, index) in AppreciationOptions" :key="index"><span v-if="option===result.assessment">X</span></td>
                <td>{{result.ws_kph_1}} km/h</td>
                <td v-if="result.winddir_1">{{formatWindDirection(result.winddir_1)}}</td>
                <td v-else></td>
                <td>{{ result.comments }}
                  <span v-if="result.status===3">
                    Inaccessible: {{ result.status_reason }}
                  </span>
                  <span v-if="result.status===1">
                    Non-Échantillonné
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center mt-20px">
      <button @click="exportToPDF" class="button-grey ">Exporter au format PDF</button>
    </div>
  </div>
</template>
<script>
import NavBar from "./NavBar.vue";
import html2pdf from "html2pdf.js";
import utils from "@/mixins/utils";
import axios from "@/axios-auth";
export default {
  data() {
    return {
      uuid: null,
      results: null,
      intensityOptions: [
        "Très faible",
        "Faible",
        "Modérée",
        "Forte",
        "Très forte",
        "Extrèmement<br/> forte",
      ],
      AppreciationOptions: [
         "Agréable",
          "Neutre",
          "Désagréable"
      ],
    }
  },
  mixins: [utils],
  components: {
    NavBar
  },
  methods: {
    getData() {
      axios
        .get("/v1/campaigns/" + this.uuid, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response)
          this.results = response.data
        })
        .catch((error) => {
          console.error(error);
        });
    },
    makeItVertical(word) {
      let formatWord = ''
      Array.from(word).forEach(e => {
        formatWord += '<div>' + e + '</div>'
      })
      return formatWord
    },
    formatWindDirection(windDir){
      let direction = null
       if(windDir>337.5 && windDir < 22.5){
          direction = 'Nord'
       }
       else if(windDir>22.5 && windDir < 67.5){
          direction = 'Nord-Est'
       }
       else if(windDir>67.5 && windDir < 112.5){
        direction = 'Est'
       }
      else if(windDir>112.5 && windDir < 157.5){
        direction = 'Sud-Est'
       }
       else if(windDir>157.5 && windDir < 202.5){
           direction = 'Sud'
      }
      else if(windDir>202.5 && windDir < 247.5){
        direction = 'Sud-Ouest'
      }
      else if(windDir>247.5 && windDir < 292.5){
        direction = 'Ouest'
      }
      else if(windDir>292.5 && windDir < 337.5){
         direction = 'Nord-Ouest'
      }
      return direction
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "Suivi_odeur_Enercycle.pdf",
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
        pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] }
      });
    },
    makeItTwoDigit(value){
      return ("0" + value).slice(-2)
    }
  },
  mounted() {
    this.uuid = this.$route.params.uuid;
    this.getData()
  }
};
</script>
<style scoped>
table {
  border: 1px solid black;
  border-collapse: collapse;
  width:100%;
}

th {
  font-size: 12px;
  padding: 5px;
  border: 1px solid black;
}

td {
  font-size: 12px;
  padding: 5px;
  border: 1px solid black;
}

.text-pdf {
  font-size: 12px;
}

.vertical-text {
  vertical-align: top;
  line-height: 1em;
  font-size: 10px;
}

.vertical-text>div {
  transform: rotate(90deg);
}

.container-pdf {
  width: calc(100% - 30px);
  overflow-y: auto;
}
</style>
