<template>
    <div>
        <div class="template-list" v-if="campaignTemplates && campaignTemplates.length > 0">
            <div v-for="(campaignTemplate, index) in campaignTemplates" :key="index" class="template-item">
                <div>{{ campaignTemplate.name }}</div>
                <div>
                    <div @click="toggleMenu(index)">
                    <CustomIcon name="three-dot"></CustomIcon>
                </div>
                <div class="template-list-menu" v-show="activeMenu.includes(index)">
                    <router-link :to="'/nouvelle-campagne/'+campaignTemplate.uuid" class="d-flex align-center">
                        <CustomIcon name="edit"></CustomIcon>
                        <div>Modifier</div>
                    </router-link>
                    <router-link :to="'/points/'+campaignTemplate.uuid" class="d-flex align-center">
                        <CustomIcon name="marker"></CustomIcon>
                        <div>Gérer les points</div>
                    </router-link>
                    <div class="d-flex align-center" @click="deleteDestinataire(campaignTemplate.uuid)">
                        <CustomIcon name="garbage"></CustomIcon>
                        <div>Supprimer</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="no-data mt-20px">Créer une tournée pour commencer</div>       
        </div>
    </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import axios from "@/axios-auth";
export default {
    data() {
        return {
            activeMenu: []
        }
    },
    props: {
        campaignTemplates: {
            default: function () {
                return [];
            },
            type: Array,
        }
    },
    components: {
        CustomIcon
    },
    methods:{
        toggleMenu(index) {
            if (this.activeMenu.includes(index)) {
                this.activeMenu.splice(this.activeMenu.indexOf(index), 1);
            } else {
                this.activeMenu.push(index);
            }
        },
        deleteDestinataire(uuid){   
            axios.delete("/v1/campaigntemplate/" + uuid, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                this.$emit('deleted', uuid)
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }
}
</script>
<style>
.template-list {
    margin-top: 20px;
    position: relative;
}
.template-item {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background-color: #edecec;
    align-items: center;
    margin-top: 15px;
    color: #313030;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
}
.template-list-menu {
  position: absolute;
  background-color: white;
  padding: 10px;
  right: 10px;
  border: 1px solid #00b156;
  z-index:10000;
}
.template-list-menu > a {
  text-decoration: none;
  color: #313030;
}
</style>