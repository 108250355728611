<template>
  <div class="container">
    <NavBar :back="'/points/' + this.$route.params.campaignPID"></NavBar>
    <h1 class="text-center">Gérer les points</h1>
    <h2 class="text-center">Ajouter un point</h2>
    <div class="mt-20px">
      <label class="label-input">No</label>
      <div>
        <input type="text" v-model="no" class="underline-input w-100" />
        <div class="error">{{ error.no }}</div>
      </div>
    </div>
    <div class="mt-20px">
      <label class="label-input">Nom</label>
      <div>
        <input type="text" v-model="name" class="underline-input w-100" />
        <div class="error">{{ error.name }}</div>
      </div>
    </div>
    <div>
      <CustomGeocoder :map="map" v-model="resultGeocoding" :initialAddress="address" :lat="lat" :lng="lng">
      </CustomGeocoder>
    </div>
    <div class="mt-20px map-instruction">
      Ou ajouter le point sur la carte en cliquant sur la carte.
    </div>
    <div class="error">{{ error.coordinates }}</div>
    <div id="map"></div>
    <ListCheckbox label="Sélectionner à qui se point sera envoyé" :options="emailOptions"
      noOptionMsg="Ajouter des destinataires avant de pouvoir sélectionner à qui sera envoyé le point" v-model="emails"></ListCheckbox>
    <div class="mt-20px d-flex space-around">
      <router-link class="button-grey" :to="'/points/' + this.$route.params.campaignPID">Annuler</router-link>
      <button class="button-grey" @click="savePoint()">Enregistrer</button>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import CustomGeocoder from "@/components/customForm/CustomGeocoder.vue";
import ListCheckbox from "@/components/customForm/ListCheckbox.vue";
import maplibregl from "maplibre-gl";
import utils from "@/mixins/utils";
//const { v4: uuidv4 } = require('uuid');
import axios from "@/axios-auth";
export default {
  data() {
    return {
      no: null,
      name: null,
      resultGeocoding: null,
      address: null,
      lat: null,
      lng: null,
      map: null,
      emails:[],
      error: {
        no: "",
        name: "",
        coordinates: "",
      },
      uuid: null,
      emailOptions: []
    };
  },
  mixins: [utils],
  watch: {
    resultGeocoding: function (val) {
      this.address = this.formatAddress(val);
      this.lat = val.geometry.coordinates[1];
      this.lng = val.geometry.coordinates[0];
    },
  },
  methods: {
    savePoint() {
      /*First check if input are valid*/
      this.error.no = "";
      let submitValid = true;
      if (!this.no) {
        submitValid = false;
        this.error.no = "Vous devez indiqué un no";
      }
      if (!this.name) {
        submitValid = false;
        this.error.name = "Vous devez indiqué un nom";
      }
      if (!this.lat || !this.lng) {
        submitValid = false;
        this.error.coordinates = "Vous devez indiqué les coordonnées du points";
      }
      if (submitValid) {
        //Then I try to send data to server
        let data = {
          no: this.no,
          name: this.name,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          point: { "type": "Point", "coordinates": [this.lng, this.lat] },
          campaignUUID: this.$route.params.campaignPID,
          emails:this.emails
        };
        if (!this.uuid) {
          //Post
          axios
            .post("/v1/samplelocation", data, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                this.$router.push('/points/' + this.$route.params.campaignPID)
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          //Put
          axios
            .put("/v1/samplelocation/" + this.uuid, data, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status == 200) {
                this.$router.push('/points/' + this.$route.params.campaignPID)
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    },
    getPoint(uuid) {
      axios
        .get("/v1/samplelocation/" + uuid, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.no = response.data.no;
          this.name = response.data.name;
          this.address = response.data.address;
          this.lat = response.data.lat;
          this.lng = response.data.lng;
          this.ctrl_DateUpdate = response.data.updatedAt;
          response.data.Emails.forEach(element=>{
            this.emails.push(element.uuid)
          })
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDestinataire() {
      axios
        .get("/v1/emails/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.emailOptions = response.data
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  components: {
    NavBar,
    CustomGeocoder,
    ListCheckbox
  },
  mounted() {
    this.getDestinataire()
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid;
      this.getPoint(this.uuid);
    }
    this.map = new maplibregl.Map({
      container: "map",
      style:
        "https://api.maptiler.com/maps/hybrid/style.json?key=Ir2QrnIiVo4Yqzg0eAU2", // stylesheet location
      center: [-72.5, 46.5], // starting position [lng, lat]
      zoom: 8, // starting zoom
      maxZoom: 17,
    });
  },
};
</script>
<style scoped>
#map {
  height: 350px;
  width: 100%;
}

.map-instruction {
  color: #646872;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
</style>
