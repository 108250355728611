<template>
  <div class="odor-form-container">
    <div class="p-30px">
      <div class="cancel" @click="cancel()">
        <CustomIcon name="chevron-left"></CustomIcon>Annuler
      </div>
      <div>
        <h2>Remplir le questionnaire</h2>
      </div>
      <div class="localisation-container">
        <div class="grey-area">
          <h3>Localisation - {{ activePoint.properties.no }}</h3>
          <div class="address">{{ activePoint.properties.address }}</div>
        </div>
        <div class="d-flex space-between">
          <div class="grey-area w-48 date-hour">{{ displayDate }}</div>
          <div class="grey-area w-48 date-hour">{{ displayHour }}</div>
        </div>
      </div>
      <div class="mt-20px">
        <CustomRadioButton :values="detection" :valueDefault="results.detecte" justifyContent="flex-start" class="mt-10px"
          v-model="results.detecte"></CustomRadioButton>
        <div v-if="results.detecte === 'Détecté'">
          <h3 class="pl-8px">Intensité</h3>
          <CustomJauge class="mt-10px" v-model="results.intensity"></CustomJauge>
          <div class="error">{{ errors.intensity }}</div>
          <div class="mt-10px">
            <h3 class="pl-8px">Persistance de l'intensité</h3>
          </div>
          <CustomRadioButton :values="persistance" :valueDefault="results.persistence" class="mt-10px"
            v-model="results.persistence"></CustomRadioButton>
          <div class="error">{{ errors.persistence }}</div>
          <CustomSelect class="mt-10px" title="Description: Catégorie" :options="descriptionCategorie"
            v-model="results.category"></CustomSelect>
          <div class="error">{{ errors.category }}</div>
          <CustomSelect v-if="results.category" class="mt-10px" title="Description: Sous-catégorie"
            :options="descriptionSubcategorie[results.category]" v-model="results.subcategory"></CustomSelect>
          <div class="error" v-if="results.category">{{ errors.subcategory }}</div>
          <div class="mt-10px">
            <h3 class="pl-8px">Appréciation</h3>
          </div>
          <CustomRadioButton :values="appreciation" :valueDefault="results.assessment" class="mt-10px"
            v-model="results.assessment"></CustomRadioButton>
          <div class="error">{{ errors.assessment }}</div>
          <div class="mt-10px">
            <h3 class="pl-8px">Commentaires</h3>
          </div>
          <textarea placeholder="Ajouter un commentaire" class="input-form mt-10px" rows="7"
            v-model="results.comments"></textarea>
        </div>
        <button class="button-green mt-10px w-100" @click="saveForm(activePoint)" v-if="results.detecte">
          <custom-icon name="plus-circle"></custom-icon>Valider le questionnaire
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import CustomJauge from "@/components/customForm/CustomJauge.vue";
import CustomSelect from "@/components/customForm/CustomSelect.vue";
import CustomRadioButton from "@/components/customForm/CustomRadioButton.vue";
import imgNez from "@/assets/images/icon_odeur_detecte_default.png";
import imgNezFalse from "@/assets/images/icon_odeur_non_detecte_default.png";

import imgNezActive from "@/assets/images/icon_odeur_detecte.png";
import imgNezFalseActive from "@/assets/images/icon_odeur_non_detecte.png";

import imgPonctuelle from "@/assets/images/ponctuelle.png";
import imgVariable from "@/assets/images/variable.png";
import imgContinue from "@/assets/images/continue.png";
import imgAgreable from "@/assets/images/agreable.png";
import imgNeutre from "@/assets/images/neutre.png";
import imgDesagreable from "@/assets/images/desagreable.png";
import imgPonctuelleActive from "@/assets/images/ponctuelleActive.png";
import imgVariableActive from "@/assets/images/variableActive.png";
import imgContinueActive from "@/assets/images/continueActive.png";
import imgAgreableActive from "@/assets/images/agreableActive.png";
import imgNeutreActive from "@/assets/images/neutreActive.png";
import imgDesagreableActive from "@/assets/images/desagreableActive.png";
import utils from "@/mixins/utils";

import { openDB, updateSample } from "@/indexedDB";
export default {
  data() {
    return {
      displayDate: null,
      displayHour: null,
      results: {
        time: null,
        detecte: null,
        intensity: null,
        persistence: null,
        assessment: null,
        category: null,
        subcategory: null,
        comments: null,
        lat: null,
        lng: null,
        status: null
      },
      appreciation: [
        {
          img: imgAgreable,
          imgActive: imgAgreableActive,
          titre: "Agréable",
          value: "Agréable",
          color: "#EBF9F1",
        },
        {
          img: imgNeutre,
          imgActive: imgNeutreActive,
          titre: "Neutre",
          value: "Neutre",
          color: "#FBF2E2",
        },
        {
          img: imgDesagreable,
          imgActive: imgDesagreableActive,
          titre: "Désagréable",
          value: "Désagréable",
          color: "#FBE6E0",
        },
      ],
      detection: [
        {
          img: imgNezFalse,
          imgActive: imgNezFalseActive,
          titre: "Détecté",
          value: "Détecté",
          color: "#EBF9F1",
        },
        {
          img:  imgNez,
          imgActive: imgNezActive,
          titre: "Non-détecté",
          value: "Non-détecté",
          color: "#FBE6E0",
        },
      ],
      persistance: [
        {
          img: imgPonctuelle,
          imgActive: imgPonctuelleActive,
          titre: "Ponctuelle",
          value: "Ponctuelle",
          color: "#EBF9F1",
        },
        {
          img: imgVariable,
          imgActive: imgVariableActive,
          titre: "Variable",
          value: "Variable",
          color: "#FBF2E2",
        },
        {
          img: imgContinue,
          imgActive: imgContinueActive,
          titre: "Continue",
          value: "Continue",
          color: "#FBE6E0",
        },
      ],
      descriptionCategorie: [
        {
          label: "Fragrance",
          value: "Fragrance",
        },
        {
          label: "Résineux boisé",
          value: "Résineux boisé",
        },
        {
          label: "Fruité (sauf agrume)",
          value: "Fruité (sauf agrume)",
        },
        {
          label: "Dégoutant 1",
          value: "Dégoutant 1",
        },
        {
          label: "Chimique",
          value: "Chimique",
        },
        {
          label: "Menthe poivrée",
          value: "Menthe poivrée",
        },
        {
          label: "Sucré",
          value: "Sucré",
        },
        {
          label: "Pop-corn",
          value: "Pop-corn",
        },
        {
          label: "Dégoutant 2",
          value: "Dégoutant 2",
        },
        {
          label: "Agrume",
          value: "Agrume",
        },
      ],
      descriptionSubcategorie: {
        Fragrance: [
          {
            label: "Floral",
            value: "Floral",
          },
          {
            label: "Sucré",
            value: "Sucré",
          },
          {
            label: "Rose",
            value: "Rose",
          },
          {
            label: "Végétal",
            value: "Végétal",
          },
          {
            label: "Vert",
            value: "Vert",
          },
          {
            label: "Herbe fraîchement coupée",
            value: "Herbe fraîchement coupée",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        "Résineux boisé": [
          {
            label: "Moisissure",
            value: "Moisissure",
          },
          {
            label: "Terre",
            value: "Terre",
          },
          {
            label: "Décomposé",
            value: "Décomposé",
          },
          {
            label: "Cèdre",
            value: "Cèdre",
          },
          {
            label: "Végétal",
            value: "Végétal",
          },
          {
            label: "Herbe fraîchement coupée",
            value: "Herbe fraîchement coupée",
          },
          {
            label: "Fumé",
            value: "Fumé",
          },
          {
            label: "Brûlé",
            value: "Brûlé",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        "Fruité (sauf agrume)": [
          {
            label: "Sucré",
            value: "Sucré",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        "Dégoutant 1": [
          {
            label: "Putride",
            value: "Putride",
          },
          {
            label: "Infect",
            value: "Infect",
          },
          {
            label: "Pourri",
            value: "Pourri",
          },
          {
            label: "Rance",
            value: "Rance",
          },
          {
            label: "Sueur",
            value: "Sueur",
          },
          {
            label: "Sur",
            value: "Sur",
          },
          {
            label: "Vinaigre",
            value: "Vinaigre",
          },
          {
            label: "Matière fécale",
            value: "Matière fécale",
          },
          {
            label: "Lait caillé",
            value: "Lait caillé",
          },
          {
            label: "Moisissure",
            value: "Moisissure",
          },
          {
            label: "Terre",
            value: "Terre",
          },
          {
            label: "Décomposé",
            value: "Décomposé",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        Chimique: [
          {
            label: "Peinture",
            value: "Peinture",
          },
          {
            label: "Liquide de nettoyage",
            value: "Liquide de nettoyage",
          },
          {
            label: "Alcool",
            value: "Alcool",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        "Menthe poivrée": [
          {
            label: "Épicé",
            value: "Épicé",
          },
          {
            label: "Sucré",
            value: "Sucré",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        Sucré: [
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        "Pop-corn": [
          {
            label: "Fumé",
            value: "Fumé",
          },
          {
            label: "Brulé",
            value: "Brulé",
          },
          {
            label: "Boisé",
            value: "Boisé",
          },
          {
            label: "Fumé",
            value: "Fumé",
          },
          {
            label: "Fumé",
            value: "Fumé",
          },
        ],
        "Dégoutant 2": [
          {
            label: "Ail",
            value: "Ail",
          },
          {
            label: "Oignon",
            value: "Oignon",
          },
          {
            label: "Fumé",
            value: "Fumé",
          },
          {
            label: "Brûlé",
            value: "Brûlé",
          },
          {
            label: "Acide",
            value: "Acide",
          },
          {
            label: "Gaz naturel",
            value: "Gaz naturel",
          },
          {
            label: "Putride",
            value: "Putride",
          },
          {
            label: "Infect",
            value: "Infect",
          },
          {
            label: "Pourri",
            value: "Pourri",
          },
          {
            label: "Égout",
            value: "Égout",
          },
          {
            label: "Caoutchouc ",
            value: "Caoutchouc ",
          },
          {
            label: "Brûlé",
            value: "Brûlé",
          },
          {
            label: "Autre",
            value: "Autre",
          },
        ],
        Agrume: [
          {
            label: "Végétal",
            value: "Végétal",
          },
          {
            label: "Herbe fraîchement coupée",
            value: "Herbe fraîchement coupée",
          },
          {
            label: "Herbe fraîchement coupée",
            value: "Herbe fraîchement coupée",
          },
        ],
      },
      errors: {

      }
    };
  },
  mixins: [utils],
  props: {
    activePoint: Object,
  },
  components: {
    CustomIcon,
    CustomJauge,
    CustomRadioButton,
    CustomSelect,
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    async saveForm(activePoint) {
      let pointToUpdate = activePoint
      this.errors = {}
      let submit = true
      if(this.results.detecte==='Détecté'){
        if (!this.results.intensity) {
          this.errors.intensity = 'Vous devez indiquer une intensité'
          submit = false
        }
        if (!this.results.persistence) {
          this.errors.intensity = 'Vous devez indiquer une persistence'
          submit = false
        }
        if (!this.results.category) {
          this.errors.category = 'Vous devez indiquer une categorie'
          submit = false
        }
        if (!this.results.subcategory) {
          this.errors.subcategory = 'Vous devez indiquer une sous-categorie'
          submit = false
        }
        if (!this.results.assessment) {
          this.errors.assessment = 'Vous devez indiquer une appréciation'
          submit = false
        }
      }
      else{
        this.results.intensity=null
        this.results.persistence=null
        this.results.category=null
        this.results.subcategory=null
        this.results.assessment=null
      }
      if (submit) {
        this.getPosition((position) => {
          this.results.lat = position.coords.latitude
          this.results.lng = position.coords.longitude
        })
        try {
          this.results.status = 2
          this.results.status_reason = null
          await openDB();
          await updateSample(pointToUpdate.properties.id, this.results);
          await this.changeUpdateDate(this.results)
          this.$emit("cancel", false);
        } catch (error) {
          console.log(error)
        }
       
      }
    }
  },
  mounted() {
    this.results.time = new Date()
    let month = this.results.time.getMonth() + 1
    this.displayDate = this.results.time.getFullYear() + '-' + month + '-' + this.results.time.getDate()
    this.displayHour = this.results.time.getHours() + ':' + this.results.time.getMinutes()
    this.results.detecte = this.activePoint.properties.detecte
    this.results.intensity = this.activePoint.properties.intensity
    this.results.persistence = this.activePoint.properties.persistence
    this.results.assessment = this.activePoint.properties.assessment
    this.results.category = this.activePoint.properties.category
    this.results.campaignUUID = this.activePoint.properties.campaignUUID
    this.results.subcategory = this.activePoint.properties.subcategory
    this.results.comments = this.activePoint.properties.comments
    this.results.status = this.activePoint.properties.status
    this.getPosition((position) => {
      this.results.lat = position.coords.latitude
      this.results.lng = position.coords.longitude
    })
  }
};
</script>
<style scoped>
.odor-form-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  height: 100vh;
  width: 100vw;
  background-color: white;
  overflow-y: scroll;
}

.odor-form-container h2 {
  color: #313030;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.localisation-container {
  background: #f4f8fd;
  padding: 8px;
}

.grey-area {
  margin-top: 15px;
  border-radius: 8px;
  background: #edecec;
  padding: 8px 12px 8px 12px;
}

h3 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date-hour {
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.address {
  color: #313030;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
