<template>
    <div class="modal-container">
        <div class="modal-error">
            <h2 class="text-center title-error">Erreur</h2>
            <p class=" text-center msg-error">{{ msg }}</p>
            <div class="d-flex justify-center">
                <button @click="$emit('close', false)" class="button-error">Fermer</button>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        msg:String
    },

}

</script>
<style scoped>
.modal-error {
  border: 1px solid #E6653D;
  padding: 15px;
  background-color: white;
  width: 70%;
}
.title-error{
    color:#E6653D;
}
.msg-error{
    color:#E6653D;
}
.button-error{
    border-radius: 8px;
    background: #E6653D;;
    color: white;
    padding: 10px;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>