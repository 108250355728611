<template>
  <div>
    <MetricsVisualiser class="position-fixed z-index2000"></MetricsVisualiser>
    <router-link to="/campagnes">
      <div class="go-back">
        <CustomIcon name="arrow-back"></CustomIcon>
      </div>
    </router-link>
    <div id="map"></div>
    <div class="track" @click="trackPosition()">
      <CustomIcon name="track"></CustomIcon>
      <div v-if="track" class="track-active"></div>
    </div>
    <div class="center-position" v-if="doNotCenter" @click="doNotCenter = !doNotCenter">
      <CustomIcon name="center-button"></CustomIcon>
    </div>
    <PopUp :feature="activePoint" v-if="popUpActive" @activate="activateOrdorForm()" @close="popUpActive = false"
      @inaccessible="inacessiblePoint()"></PopUp>
    <transition name="fade">
      <OdorForm v-if="odorFormActive" @cancel="(e) =>{(odorFormActive = e);updateTrajet()}" :activePoint="activePoint"></OdorForm>
    </transition>
    <transition name="fade">
      <UnreachablePoint v-if="unreachableFormActive" @cancel="(e) =>{(unreachableFormActive = e);updateTrajet()} "
        :activePoint="activePoint"></UnreachablePoint>
    </transition>
  </div>
</template>
<script>
//Mixins
import Utils from "@/mixins/utils.js";
import maplibregl from "maplibre-gl";
import 'maplibre-gl/dist/maplibre-gl.css';
import MetricsVisualiser from "@/components/MetricsVisualiser.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import OdorForm from "@/components/OdorForm.vue";
import UnreachablePoint from "@/components/UnreachablePoint.vue";
import PopUp from "@/components/customContainer/PopUp.vue";
import pin_yellow from "@/assets/images/pin_yellow.png";
import pin_green from "@/assets/images/pin_green.png";
import pin_red from "@/assets/images/pin_red.png";
import black_dot from "@/assets/images/black_dot.png";
import navigation from "@/assets/images/navigation.png";
import * as turf from "@turf/turf";
import { isProxy, toRaw } from "vue";
//Toast
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

//IndexedDB
import {
  openDB,
  GetAllObjectsFromStoreFilter
} from "@/indexedDB";

export default {
  data() {
    return {
      pointsEchantillons: null,
      usersPosition: [-72.75, 46.5],
      track: false,
      pin_yellow,
      pin_green,
      pin_red,
      navigation,
      navigationMarker: null,
      odorFormActive: false,
      unreachableFormActive: false,
      popUpActive: false,
      activePoint: null,
      doNotCenter: false,
      lastNearest: null,
      message: "",
      markers:[],
      realPositionMarkers:[],
      heading:null
    };
  },
  mixins: [Utils],
  methods: {
    async getSamplePoint() {
      let echantillons;
      const campaignUUID = this.$route.params.uuid;
      try {
          await openDB();
          echantillons = await GetAllObjectsFromStoreFilter(
            "campaignUUID",
            campaignUUID,
            "sample"
          );
         
          this.pointsEchantillons = this.createSamples(echantillons);
          this.addTrajet(); 
    

      } catch (error) {
        console.error(error);
      }
    },
    createSamples(data) {
      let echantillons = {
        type: "FeatureCollection",
        features: [],
      };
      data.forEach((element) => {
        let feature = {
          type: "Feature",
          properties: element,
          geometry: {
            coordinates: [element.sample_location_lng, element.sample_location_lat],
            type: "Point",
          },
        };
        echantillons.features.push(feature);
      });
      return echantillons;
    },
    notify() {
      toast(this.message, {
        autoClose: 1000,
        theme: "colored",
        type: "success",
      }); // ToastOptions
    },
    addTrajet() {
      let echantillons = this.pointsEchantillons;
      if (isProxy(this.pointsEchantillons)) {
        echantillons = toRaw(this.pointsEchantillons);
      }
      if (echantillons) {
        echantillons.features.forEach((feature) => {
          var marker = document.createElement("div");
          if (feature.properties.status === 1) {
            marker.style.backgroundImage = "url(" + this.pin_yellow + ")";
            marker.style.color = "#e8ae4a";
          } else if (feature.properties.status === 2) {
            marker.style.backgroundImage = "url(" + this.pin_green + ")";
            marker.style.color = "#00B156";
          } else if (feature.properties.status === 3) {
            marker.style.backgroundImage = "url(" + this.pin_red + ")";
            marker.style.color = "#E6653D";
          }
          marker.innerHTML = feature.properties.no;
          marker.style.backgroundSize = "30px 30px";
          marker.style.width = "30px";
          marker.style.height = "30px";
          marker.style.cursor = "pointer";
          marker.className = "markerPosition";
          const markerElement = new maplibregl.Marker({
            className: "marker",
            element: marker,
          }).setLngLat(feature.geometry.coordinates);
          this.markers.push(markerElement)
          markerElement.addTo(this.map);
          markerElement
            .getElement()
            .addEventListener("click", () => this.popUpActivator(feature));
          this.setRealPositionMarker(feature)
        });
      }
      this.fitBBox(echantillons);
    },
    setRealPositionMarker(feature){
      if(feature.properties.lng && feature.properties.lat){

        var realPositionMarker = document.createElement("div");
        realPositionMarker.innerHTML = feature.properties.no;
        realPositionMarker.style.color = "#fff";
        realPositionMarker.style.fontSize = "10px";
        realPositionMarker.style.textAlign = "center";
        realPositionMarker.style.backgroundSize = "15px 15px";
        realPositionMarker.style.width = "15px";
        realPositionMarker.style.height = "15px";
        realPositionMarker.style.backgroundImage = "url(" + black_dot + ")";
        const realMarkerElement = new maplibregl.Marker({
            className: "marker",
            element: realPositionMarker,
          }).setLngLat([feature.properties.lng, feature.properties.lat]);
          this.realPositionMarkers.push(realMarkerElement)
          realMarkerElement.addTo(this.map);
      }
    },
    fitBBox(data) {
      // Get the bounding box of the GeoJSON data
      var bounds = new maplibregl.LngLatBounds();
      if (data.features.length > 1) {
        data.features.forEach(function (feature) {
          bounds.extend(feature.geometry.coordinates);
        });
        this.map.fitBounds(bounds, { padding: 60 });
      }
      else {
        this.map.flyTo({
          center: data.features[0].geometry.coordinates,
          zoom: 17,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }


    },
    updateTrajet(){
      this.markers.forEach(element=>{
        element.remove();
      }) 
      this.realPositionMarkers.forEach(element=>{
        element.remove();
      })
      this.getSamplePoint();
      this.popUpActive=false
    },
    popUpActivator(activePoint) {
      this.popUpActive = true;
      this.activePoint = activePoint;
    },
    activateOrdorForm() {
      this.odorFormActive = true;
    },
    trackPosition() {
      let vm = this;
      this.track = !this.track;
      if (this.track) {
        vm.getPosition(this.showPosition);
        this.interval = setInterval(function () {
          vm.getPosition(vm.showPosition);
        }, 2000);
        this.map.on("touchstart", this.removeCenter);
        this.map.on("wheel", this.removeCenter);
      } else {
        this.removePosition();
        clearInterval(this.interval);
        this.map.off("touchstart", this.removeCenter);
        this.map.off("wheel", this.removeCenter);
      }
    },
    removeCenter() {
      this.doNotCenter = true;
    },
    showPosition(position) {
      if (this.navigationMarker) {
        this.navigationMarker.remove();
      }
      this.usersPosition = [
        position.coords.longitude,
        position.coords.latitude,
      ];
      console.log(position.coords.heading)
      var el = document.createElement("div");
      el.style.backgroundImage = "url(" + navigation + ")";
      el.style.backgroundSize = "55px 55px";
      el.style.width = "55px";
      el.style.height = "55px";
      el.style.transform= "rotate("+position.coords.heading+"deg)"
      this.navigationMarker = new maplibregl.Marker({
        className: "marker",
        element: el,
      })
        .setLngLat(this.usersPosition)
        .addTo(this.map);
      if (!this.doNotCenter) {
        this.centerTo(this.usersPosition);
      }
      this.isNearAPoint(this.usersPosition, this.pointsEchantillons);
    },
    removePosition() {
      this.alreadyCenter = false;
      this.lastNearest = null;
      if (this.navigationMarker) {
        this.navigationMarker.remove();
      }
    },
    centerTo(usersPosition) {
      this.map.flyTo({
        center: usersPosition,
        zoom: 17,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    },
    isNearAPoint(usersPosition, pointsEchantillons) {
      /*first I create a buffer*/
      let point = turf.point(usersPosition);
      let buffered = turf.buffer(point, 0.075, { units: "kilometers" });
      if (pointsEchantillons) {
        let nearest = turf.nearestPoint(point, pointsEchantillons);
        if (
          turf.booleanPointInPolygon(nearest, buffered) &&
          (this.lastNearest === null ||
            this.lastNearest.properties.no !== nearest.properties.no)
            && nearest.properties.status === 1
        ) {
          this.lastNearest = nearest;
          new Audio("../notifications.mp3").play();
          this.message = `Vous êtes à moins de 100 m du point ${this.lastNearest.properties.no}`;
          this.notify();
          this.activePoint=this.lastNearest
          this.odorFormActive = true
        }
      }
    },
    inacessiblePoint() {
      this.unreachableFormActive = true
    }
  },
  components: {
    CustomIcon,
    OdorForm,
    MetricsVisualiser,
    PopUp,
    UnreachablePoint
  },
  mounted() {
    this.getSamplePoint();
    this.map = new maplibregl.Map({
      container: "map",
      style:
        "https://api.maptiler.com/maps/streets-v2/style.json?key=Ir2QrnIiVo4Yqzg0eAU2", // stylesheet location
      center: this.usersPosition, // starting position [lng, lat]
      zoom: 10, // starting zoom
      maxZoom: 17,
    });
    let vm = this;
    this.map.on("load", function () {
      vm.addTrajet();
    });
  },
};
</script>
<style scoped>
.zoom-level{
  z-index:100000;
  background-color: white;
  position:fixed;
  bottom:2px;
  left:10px;
  color:black;
}
#map {
  height: 100vh;
  width: 100vw;
}

.go-back {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
  border-radius: 0px 8px 8px 0px;
  background: #313030;
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
}
.track {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 5000;
}
.center-position {
  position: absolute;
  bottom: 5px;
  right: 80px;
  z-index: 5000;
}
.track-active {
  height: 20px;
  width: 20px;
  background-color: #00b156;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
}
.title-popup {
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
