<template>
  <div class="container campaign-container">
    <nav-bar :campaign="true"></nav-bar>
    <div>
      <h1>Dernières tournées</h1>
    </div>
    <div v-if="months.length < 1">
      <div class="no-data">Créer une campagne pour commencer</div>
    </div>
    <div v-else>
      <div v-for="(month, index) in months" :key="index">
        <h2>{{ month.month }}</h2>
        <table cellspacing="0">
          <thead>
            <td>Date</td>
            <td>Heure</td>
          </thead>
          <tbody>
            <tr v-for="(campagne, subindex) in month.campagnes" :key="subindex">
              <td>{{ campagne.date }}</td>
              <td>{{ campagne.heure }}</td>
              <td>
                <div class="bubble-name-small" @click="menuActive = !menuActive" v-if="campagne.username"
                  :class="{ 'not-me': userid !== campagne.user_id }">
                  {{ getInitials(campagne.username) }} 
                </div>
              </td>
              <td>
                <div v-if="campagne.serverSend === null" @click="saveCampaign(campagne.campaignPID)">
                  <custom-icon name="arrow" fill="#D43D94"></custom-icon>
                </div>
                <div v-else-if="campagne.serverSend == 'to date'">
                  <div class="green-circle"></div>
                </div>
                <div v-else-if="campagne.serverSend == 'to update'" @click="saveCampaign(campagne.campaignPID)">
                  <custom-icon name="update" fill="#E67671"></custom-icon>
                </div>
              </td>
              <td class="position-relative">
                <div class="cursor-pointer pbt-10px" @click="menuActive(index, subindex)">
                  <custom-icon name="three-dot"></custom-icon>
                </div>
                <div class="menu" v-if="
                  activeMenu !== null &&
                  activeMenu.subindex === subindex &&
                  activeMenu.index === index
                ">
                  <router-link class="d-flex align-center" :to="'/map/' + campagne.campaignPID"
                    v-if="userid == campagne.user_id || admin">
                    <custom-icon name="edit"></custom-icon> Modifier
                  </router-link>
                  <router-link v-if="campagne.serverSend !== null" :to="'/rapport/' + campagne.campaignPID"
                    class="d-flex align-center">
                    <custom-icon name="download"></custom-icon> Télécharger
                  </router-link>
                  <div v-if="campagne.serverSend !== null" class="d-flex align-center"
                    @click="confirmSend = true; activeSend = campagne.campaignPID;">
                    <custom-icon name="send"></custom-icon> Envoyer
                  </div>
                  <div class="d-flex align-center" v-if="userid == campagne.user_id || admin" @click="
                    confirmDelete = true;
                  menuActive(index, subindex);
                  activeDelete = campagne.campaignPID;
                  activeServerSend = campagne.serverSend;
                  ">
                    <custom-icon name="garbage"></custom-icon> Supprimer
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="!$store.state.internetConnection" class="offline-container mt-20px">
        Connecter vous à Internet pour télécharger les campagnes à partir du serveur
    </div>
    <div v-if="enoughData && $store.state.internetConnection" class="offline-container mt-20px">
        Vous avez chargé toutes les données
    </div>
    <div v-else-if="$store.state.internetConnection">
      <button class="button-green mt-20px" @click="getMoreData()">Charger des campagnes du serveurs</button>
    </div>
    <AlertMessage v-show="confirmDelete" title="Supprimer" msg="Etes-vous sur de vouloir supprimer cette tournée?"
      buttonMsg="Supprimer" @cancel="(e) => (confirmDelete = e)" @submit="deleteCampaign()"></AlertMessage>

    <AlertMessage v-show="confirmSend" title="Envoyer" msg="Etes-vous sur de vouloir envoyer cette tournée?"
      buttonMsg="Envoyer" @cancel="(e) => (confirmSend = e)" @submit="sendEmail(activeSend)"></AlertMessage>
    <ErrorMessage :msg="msg" v-if="errorMsg" @close="errorMsg = false"></ErrorMessage>
  </div>
</template>
<script>
import {
  openDB,
  addObjectToStore,
  updateObjectFromStore,
  getAllObjectsFromStore,
  GetAllObjectsFromStoreFilter,
  deleteObjectFromStore,
  clearObjectStore
} from "@/indexedDB";
import { jwtDecode } from "jwt-decode";
import CustomIcon from "@/components/CustomIcon.vue";
import NavBar from "@/components/NavBar.vue";
import AlertMessage from "@/components/customContainer/AlertMessage.vue";
import ErrorMessage from "@/components/customContainer/ErrorMessage.vue";
import axios from "@/axios-auth";
export default {
  data() {
    return {
      activeDelete: null,
      activeServerSend: null,
      activeSend: null,
      activeMenu: null,
      months: [],
      confirmDelete: false,
      confirmSend: false,
      localData: [],
      errorMsg: false,
      msg: null,
      userid: null,
      admin: JSON.parse(localStorage.getItem("isAdmin")),
      enoughData:false,
      offset:0,
      serverData:null
    };
  },
  components: {
    CustomIcon,
    AlertMessage,
    NavBar,
    ErrorMessage
  },
  methods: {
    menuActive(index, subindex) {
      if (this.activeMenu === null) {
        this.activeMenu = { index, subindex };
      } else {
        this.activeMenu = null;
      }
    },
    getSamplePoints() {
      //First try to get data from server
      axios
        .get("/v1/SampleLocations/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          openDB();
          clearObjectStore("sample_location");
          response.data.data.forEach((element) => {
            addObjectToStore(element, "sample_location");
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCampaignsFromServer() {
      await axios
        .get("/v1/campaigns", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data) {
            if(response.data.length!==20){
              this.enoughData=true
            }
            else{
              this.enoughData=false
            }
            this.compareData(response.data)
            this.serverData=response.data
          }
          else {
            this.retrieveFromIndexedDB();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async retrieveFromIndexedDB() {
      try {
        await openDB();
        const data = await getAllObjectsFromStore("campaign");
        this.localData = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reverse()
        this.months = this.formatData(this.localData);
      } catch (error) {
        console.error(error);
      }
    },
    formatData(data) {
      const fullMonths = [
        "Janvier",
        "Févrer",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
      ];
      let months = [];
      let lastMonth;
      let campaign = [];
      data.forEach((element, key) => {
        let d = new Date(element.createdAt);
        let year = d.getFullYear();
        let month = d.getMonth();
        let monthYear = fullMonths[month] + " " + year;
        if((element.user_id===this.userid && element.serverSend===null) || (element.serverSend!==null) ){
          if (!lastMonth) {
          lastMonth = monthYear;
          campaign.push({
            campaignPID: element.uuid,
            serverSend: element.serverSend,
            user_id: element.user_id,
            username: element.username,
            createdAt:element.createdAt,
            date: d.getDate() + " " + fullMonths[d.getMonth()],
            heure:
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes(),
          });
        } else if (lastMonth !== monthYear) {
          months.push({ month: lastMonth, campagnes: campaign });
          lastMonth = monthYear;
          campaign = [];
          campaign.push({
            campaignPID: element.uuid,
            user_id: element.user_id,
            serverSend: element.serverSend,
            username: element.username,
            createdAt:element.createdAt,
            date: d.getDate() + " " + fullMonths[d.getMonth()],
            heure:
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes(),
          });
        } else {
          campaign.push({
            campaignPID: element.uuid,
            serverSend: element.serverSend,
            user_id: element.user_id,
            username: element.username,
            createdAt:element.createdAt,
            date: d.getDate() + " " + fullMonths[d.getMonth()],
            heure:
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes(),
          });
        }
        }

        if (key === data.length - 1) {
          months.push({ month: lastMonth, campagnes: campaign });
        }
      });
      return months;
    },
    async saveCampaign(pid) {
      let data = {
        "uuid": pid,
      }
      let echantillons = await this.getSamples(pid)
      let samples = []
      echantillons.forEach(element => {
        let sample = {
          "uuid": element.uuid,
          "detecte": element.detecte,
          "intensity": element.intensity,
          "persistance": element.persistence,
          "category": element.category,
          "subcategory": element.subcategory,
          "assessment": element.assessment,
          "comments": element.comments,
          "lat": element.lat,
          "lng": element.lng,
          "sample_location_lat": element.sample_location_lat,
          "sample_location_lng": element.sample_location_lng,
          "status": element.status,
          "status_reason": element.status_reason,
          "sampleLocationUUID": element.sampleLocationUUID,
          "createdAt":element.time
        }
        samples.push(sample)
      })
      data.samples = samples
      axios
        .post("/v1/campaign", data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          console.log(response)
          if (response.response && response.response.status === 403) {
            this.confirmDelete = false;
            this.errorMsg = true
            this.msg = response.response.data
          }
          if(response.status && response.status===200){
            this.months.forEach(element => {
             element.campagnes.find(element => element.campaignPID === pid).serverSend = 'to date'
            })
            await updateObjectFromStore('uuid', pid, "campaign", 'serverSend', 'to date');
          }

        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteCampaign() {
      if (this.activeServerSend !== null) {
        axios.delete("/v1/campaign/" + this.activeDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response.response && response.response.status === 403) {
            this.confirmDelete = false;
            this.errorMsg = true
            this.msg = response.response.data
          }
          else if (response.status === 200) {
            this.confirmDelete = false;
            deleteObjectFromStore("campaignUUID", this.activeDelete, 'sample');
            deleteObjectFromStore("uuid", this.activeDelete);
            this.retrieveFromIndexedDB();
          }

        }).catch(error => {
          console.log(error)
        })
      }
      else {
        this.confirmDelete = false;
        deleteObjectFromStore("uuid", this.activeDelete);
        this.retrieveFromIndexedDB();
      }


    },
    async getSamples(campaignUUID) {
      try {
        await openDB();
        let echantillons = await GetAllObjectsFromStoreFilter(
          "campaignUUID",
          campaignUUID,
          "sample"
        );
        return echantillons
      } catch (error) {
        console.error(error);
      }
    },
    async compareData(serverData) {
      await openDB();
      let commonCampaign = []
      let uniqueServerData = []
      let uniqueLocalData = []
      let campaigns = await getAllObjectsFromStore("campaign")
      const serverDataMap = new Map(serverData.map(item => [item.uuid, item]));
      campaigns.forEach(item => {
        if (serverDataMap.has(item.uuid)) {
          commonCampaign.push(item);
        } else {
          uniqueLocalData.push(item);
        }
      })
      // Find unique items in array1
      uniqueServerData = serverData.filter(item => !campaigns.some(el => el.uuid === item.uuid));
      this.handleDifference(commonCampaign, uniqueServerData, uniqueLocalData, serverDataMap)
    },
    async handleDifference(commonCampaign, uniqueServerData, uniqueLocalData, serverDataMap){
      await openDB();
      for(let i=0; i<commonCampaign.length; i++){
        if (new Date(commonCampaign[i].updatedAt) <= new Date(serverDataMap.get(commonCampaign[i].uuid).updatedAt)) {
            await updateObjectFromStore('uuid', commonCampaign[i].uuid, "campaign", 'serverSend', 'to date')
        }
        else {
            await updateObjectFromStore('uuid', commonCampaign[i].uuid, "campaign", 'serverSend', 'to update')
        }
      }
      for(let i=0; i<uniqueServerData.length; i++){
      
        let campaignData = {
            uuid: uniqueServerData[i].uuid,
            createdAt: new Date(uniqueServerData[i].createdAt),
            updatedAt: new Date(uniqueServerData[i].updatedAt),
            serverSend: 'to date',
            user_id: uniqueServerData[i].users_id,
            username: uniqueServerData[i].User.username,
          };
          try {
            await addObjectToStore(campaignData, "campaign");
            for(let j=0; j<uniqueServerData[i].samples.length; j++){

             let sample =  {
              uuid: uniqueServerData[i].samples[j].uuid, 
              address: uniqueServerData[i].samples[j].sample_location.address, 
              assessment:uniqueServerData[i].samples[j].assessment,
              campaignUUID: uniqueServerData[i].uuid, 
              category:uniqueServerData[i].samples[j].category,
              comments:uniqueServerData[i].samples[j].comments,
              detecte:uniqueServerData[i].samples[j].detecte,
              intensity:uniqueServerData[i].samples[j].intensity,
              lat:uniqueServerData[i].samples[j].lat,
              lng:uniqueServerData[i].samples[j].lng,
              no: uniqueServerData[i].samples[j].sample_location.no,
              name: uniqueServerData[i].samples[j].sample_location.name, 
              persistence:uniqueServerData[i].samples[j].persistance,
              sampleLocationUUID:uniqueServerData[i].samples[j].sample_location.uuid,
              sample_location_lat:uniqueServerData[i].samples[j].sample_location_lat,
              sample_location_lng:uniqueServerData[i].samples[j].sample_location_lng,
              status:uniqueServerData[i].samples[j].status,
              status_reason:uniqueServerData[i].samples[j].status_reason,
              subcategory:uniqueServerData[i].samples[j].subcategory,
              time:uniqueServerData[i].samples[j].createdAt,
             
            }
            await addObjectToStore(sample, "sample");
          }
        }
        catch (error) {
          console.error(error);
        }
      }
      for(let i=0; i<uniqueLocalData.length; i++){
        if(uniqueLocalData[i].serverSend=='to date'){
          try {
            await deleteObjectFromStore('uuid', uniqueLocalData[i].uuid );
          }
          catch (error) {
            console.error(error);
          }
        }  
      }
      this.retrieveFromIndexedDB();
    },
    sendEmail(activeSend) {
      let data = { 'campaignUUID': activeSend }
      axios.post('/v1/sendmail', data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          "Content-Type": "application/json",
        }
      }).then(result => {
        if (result.status === 200) {
          this.confirmSend = false
          this.activeMenu = null

        }
      }).catch(error => {
        console.log(error)
      })
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
    decodeJWT() {
      try {
        this.userid = jwtDecode(localStorage.getItem('jwtToken')).id;
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    },
    async getMoreData(){
      this.offset=this.offset+20
      await axios
      .get("/v1/campaigns?offset="+this.offset, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.serverData=this.serverData.concat(response.data);
          this.compareData(this.serverData) 

          if(response.data.length!==20){
              this.enoughData=true
            }
            else{
              this.enoughData=false
            }
        }).catch(error=>{
          console.log(error);
        })
    }
  },
  async mounted() {
    this.decodeJWT()
    //First get data from the server()
    await this.getCampaignsFromServer()

  },
};
</script>
<style scoped>
.campaign-container table {
  width: 100%;
  border-spacing: 0 10px;
}

.campaign-container tr {
  background: #edecec;
  width: 100%;
  margin-top: 15px;
}

.campaign-container tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: #313030;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.menu {
  position: absolute;
  background-color: white;
  right: 0;
  padding: 5px;
  z-index: 1000;
  border: 1px solid #00b156;
  color: #313030;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100px;
  margin-top: 15px;
}

.bubble-name-small {
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-color: #73acdf;
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  margin-left: 10px;
}

.not-me {
  background-color: #e8ae4a
}

.menu a {
  text-decoration: none;
  color: #313030;
}
</style>
