<template>
  <div class="metrics-container">
    <MetricsContainer title="Position" :value="coordinates" class="w-100"></MetricsContainer>
    <MetricsContainer title="Vent" :value="wind" class="w-100"></MetricsContainer>
    <MetricsContainer title="Pression" :value="pression" class="w-100"></MetricsContainer>
    <MetricsContainer title="Température" :value="temp" class="w-100"></MetricsContainer
    ><MetricsContainer title="Heure" :value="hour" class="w-100"></MetricsContainer>
  </div>
</template>
<script>
import MetricsContainer from "@/components/customContainer/MetricsContainer.vue";
/*Mixins*/
import Utils from "@/mixins/utils.js";
/*Lib*/
import axios from 'axios'

export default {
  mixins: [Utils],
  data() {
    return {
      coordinates: null,
      wind: null,
      pression: null,
      temp: null,
      hour: null,
      lat:null,
      lng:null,
      intervals: {
        time: null,
      },
    };
  },
  methods: {
    getMeteo(){
      let lat = 46
      let lng =-71
      if(this.lat && this.lng ){
        lat=this.lat
        lng=this.lng
      }

        axios.get('https://api.open-meteo.com/v1/forecast?latitude='+lat+'&longitude='+lng+'&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m').then((response)=>{
            this.wind=response.data.current.wind_speed_10m + 'km/h'
            this.temp=response.data.current.temperature_2m + ' C'
        })
    }
  },
  components: {
    MetricsContainer,
  },
  mounted() {
    let vm = this;
    this.intervals.time = setInterval(function () {
      vm.hour = vm.currentTime();
    }, 1000);
    vm.getPosition(
        (position) =>
          (vm.coordinates =
            position.coords.longitude + ", " + position.coords.latitude)
      );
    this.intervals.position = setInterval(function () {
      vm.getPosition(
        (position) =>
          (vm.coordinates =
            position.coords.longitude + ", " + position.coords.latitude)
      );
    }, 5000);
    this.getMeteo()
  },
};
</script>
<style scoped>
.metrics-container {
  display: flex;
  width:100%;
}
</style>
