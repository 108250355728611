<template>
  <div class="jauge-container">

    <div class="jauge" @click="(e)=>moveJauge(e)" ref="jauge">
        <div class="jauge-filled"></div>
        <div class="jauge-cutter" ref="jaugeCutter"></div>
        <div class="jauge-circle" ref="jaugeCircle"></div>   
    </div>
    <div class="label-jauge-container">
      <div class="label-jauge" v-for="(label, index) in labels" :key="index" v-html="label" :class="{labelactive: activeValue== label}">
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      activeValue:null,
      labels: [
        "Très faible",
        "Faible",
        "Modérée",
        "Forte",
        "Très forte",
        "Extrèmement<br/> forte",
      ]
    };
  },
  props:{
    modelValue: String
  },
  watch: {
    modelValue(newVal) {
      this.activeValue = newVal;
      this.setJauge()
    }
  },
  methods:{
    moveJauge(e){
        let offset=e.clientX - this.$refs.jauge.offsetLeft
        let button = this.$refs.jaugeCircle
        let cutter = this.$refs.jaugeCutter
        button.style.left=offset+'px'
        button.style.transform='translateX(-50%)'
        cutter.style.width = 'calc(100% - '+offset+'px)'
        cutter.style.left = offset+'px'
        this.actualOffset=offset
        let position = this.actualOffset/this.$refs.jauge.clientWidth
        let index = Math.round(this.labels.length * position);
        if(index>5){
          index=5
        }
        this.activeValue=this.labels[index]
        this.$emit('update:modelValue', this.activeValue)
    },
    setJauge(){
        let offset = (this.$refs.jauge.clientWidth / this.labels.length) * (this.labels.indexOf(this.activeValue))
        let button = this.$refs.jaugeCircle
        let cutter = this.$refs.jaugeCutter
        button.style.left=offset+'px'
        button.style.transform='translateX(-50%)'
        cutter.style.width = 'calc(100% - '+offset+'px)'
        cutter.style.left = offset+'px'
    }
  },
  mounted(){

  }
};
</script>
<style scoped>
.jauge {
  height: 32px;
  border-radius: 25px;
  position:relative;
  width:100%;
}
.jauge-circle {
  height: 35px;
  width: 35px;
  background-color: #313030;
  border-radius: 50%;
  position:absolute;
  top:-1px;
  left:0;
}
.label-jauge-container{
    display: flex;
    justify-content: space-between;
    padding-top:10px;
}
.label-jauge{
    writing-mode: vertical-rl;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.jauge-filled{
    height:100%;
    width:100%;
    border-radius: 25px;
    background: linear-gradient(90deg, #00B156 0%, #E8AE4A 50.29%, #E6653D 96.78%);
}
.jauge-cutter{
    border-radius: 25px;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: var(--Light-grey, #edecec);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.labelactive{
  color:#00B156;
  font-weight:bold;
}
</style>
