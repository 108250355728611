import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './registerServiceWorker'

const app = createApp(App)
//Add store VueX
app.use(store)
//Add router
app.use(router)


app.mount('#app')
