<template>
    <div class="container">
        <NavBar></NavBar>
        <h1 class="text-center">Gérer les destinataires</h1>
        <router-link class="button-green" to="/destinataire">
            <custom-icon name="users" fill="#fff"></custom-icon>Ajouter un destinataire
        </router-link>
        <list-destinataire :emails="emails" @deleted="(e)=>{spliceDestinataire(e)}"></list-destinataire>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import ListDestinataire from '@/components/ListDestinataire.vue'
import axios from '@/axios-auth'
export default {
    data(){
        return{
            emails:[]
        }
    },
    methods:{
        getEmails(){
            axios.get('/v1/emails', {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
                  'Content-Type': 'application/json'
                }
              }).then((response)=>{
                this.emails=response.data
            }).catch()
        },
        spliceDestinataire(uuid){
            this.emails.splice(this.emails.findIndex(element=>element.uuid===uuid), 1)
        }
    },
    components: {
        NavBar,
        ListDestinataire
    },
    mounted(){
        this.getEmails()
    }
}

</script>