import { isProxy, toRaw } from "vue";
import {updateObjectFromStore } from "@/indexedDB";
export default {
  methods: {
    currentTime() {
      let date = new Date();
      let hh = date.getHours();
      let mm = date.getMinutes();
      let ss = date.getSeconds();
      let session = "AM";

      if (hh == 0) {
        hh = 12;
      }
      if (hh > 12) {
        hh = hh - 12;
        session = "PM";
      }

      hh = hh < 10 ? "0" + hh : hh;
      mm = mm < 10 ? "0" + mm : mm;
      ss = ss < 10 ? "0" + ss : ss;

      let timestamp = hh + ":" + mm + ":" + ss + " " + session;

      return timestamp;
    },
    getPosition(success) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, this.showError, {
          enableHighAccuracy: true,
        });
      } else {
        //alert("Position non-disponible");
      }
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    },
    formatAddress(data){
      if (isProxy(data)) {
        data = toRaw(data);
      }
      let address =""
      if (data.properties.name) {
        address = data.properties.name + " ";
      }
      if (data.properties.housenumber) {
        address += data.properties.housenumber + " ";
      }
      if (data.properties.street) {
        address += data.properties.street+ " ";
      }
      if (data.properties.housenumber || data.properties.street) {
        address += ", ";
      }

      if (data.properties.city) {
        address += data.properties.city;
        address += ", ";
      }

      if (data.properties.county) {
        address += data.properties.county;
        address += ", ";
      }
      if (data.properties.country) {
        address += data.properties.country;
      }
      return address
    },
    async changeUpdateDate(result){
      let udpated = await updateObjectFromStore('uuid', result.campaignUUID, "campaign", 'updatedAt', new Date())
      if(udpated.serverSend==='to date'){
        await updateObjectFromStore('uuid', result.campaignUUID, "campaign", 'serverSend', 'to update')
      }
    }
  },
};
