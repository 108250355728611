<template>
  <div class="modal-container">
    <div class="modal">
      <h2 class="text-center modal-title">{{ title }}</h2>
      <p class="modal-msg">{{ msg }}</p>
      <div class="d-flex space-around">
        <button class="button-grey" @click="$emit('cancel', false)">Annuler</button>
        <button class="button-grey" @click="$emit('submit')">{{ buttonMsg }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    msg:String,
    buttonMsg:String
  }
};
</script>
<style scoped>
.modal {
  border: 1px solid #00b156;
  padding: 15px;
  background-color: white;
  width: 70%;
  max-width: 400px;
}
.modal-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.modal-msg{
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>
