<template>
    <div class="unreachable-container">
        <div class="p-30px">
            <div class="cancel" @click="cancel()">
                <CustomIcon name="chevron-left"></CustomIcon>Annuler
            </div>
            <div>
                <h2>Pour quelle raison le point est-il inaccessible?</h2>
                <textarea placeholder="Raison" class="input-form mt-10px" rows="7" v-model="reason">
                </textarea>
                <button class="button-green mt-10px w-100" @click="inacessiblePoint(activePoint)">
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue";
import { openDB, updateSample } from "@/indexedDB";
import utils from "@/mixins/utils";
export default {
    data() {
        return {
            reason: null,
            results:{
                lat:null,
                lng:null
            }
        }
    },
    props: {
        activePoint: String
    },
    mixins: [utils],
    methods: {
       async inacessiblePoint() {
            let inaccessibleResult=
            { 
                assessment:null,
                category:null,
                comments:null,
                intensity:null,
                lat:this.results.lat,
                lng:this.results.lng,
                persistence:null,
                status:3,
                status_reason:this.reason,
                subcategory:null,
                time:null,
            }
                try{
                    await openDB();
                    await updateSample( this.activePoint.properties.id, inaccessibleResult);
                    await this.changeUpdateDate(this.activePoint.properties)

                }catch(error){
                    console.log(error)
                }
                this.$emit("cancel", false);
        },
        cancel() {
            this.$emit("cancel", false);
        },
    },
    components: {
        CustomIcon
    },
    mounted(){
        this.reason=this.activePoint.properties.status_reason
        this.getPosition((position) => {
            this.results.lat = position.coords.latitude
            this.results.lng = position.coords.longitude
        })
    }
}
</script>
<style>
.unreachable-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
    height: 100vh;
    width: 100vw;
    background-color: white;
    overflow-y: scroll;
}
</style>