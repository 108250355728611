<template>
    <div class="loader-container" v-show="this.$store.state.loading">
        <div class="loader">
          <img src="/img/icons/android-chrome-maskable-192x192.png"/>
        </div>
    </div>
</template>
<style scoped>
.loader-container{
    position:fixed;
    top:0;
    left:0;
    z-index:10000;
    background-color: rgba(0,0,0,0.5);
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}
.loader {
  width: 160px;
  height: 185px;
  position: relative;
}
.loader>img{
  max-width: 100%;
  max-height: 100%;
  animation-name: upAndDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes upAndDown {
  0%{
    transform: scale(0.8);
  }
  100%{
    transform: scale(1);
  }
}
</style>