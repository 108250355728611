<template>
    <div class="mt-20px" >
        <div v-if="options.length > 0">
            <label class="label-input">{{ label }}</label>
            <label class="container-checkbox" v-for="(option, index) in options" :key="index">
                <input type="checkbox" v-model="emails" :value="option.uuid" @change="$emit('update:modelValue', emails)">
                <span class="checkmark"></span><div>{{option.firstName}} {{option.lastName}}<br/> {{option.email}}</div>
            </label>
        </div>
        <div v-else class="no-data mt-20px">
            <div>{{ noOptionMsg }}</div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            emails:[]
        }
    },
    props:{
        label:String,
        options:Array,
        noOptionMsg:String,
        modelValue:Array
    },
    mounted(){
      this.emails=this.modelValue
    }
}
</script>
<style scoped>
.container-checkbox {
  display: flex;
  justify-content: flex-start;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top:10px;
  font-size:13px;
  background-color:#f4f8fd;
  padding:10px;
  border-radius: 5px;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: relative;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border:1px solid #313030;
  border-radius: 2px;
  margin-right: 10px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #00B156;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>