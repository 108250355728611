<template>
    <div class="container">
        <NavBar back="/configurer-les-destinataires"></NavBar>
        <h1 class="text-center">Ajouter un destinataire</h1>
        <div class="mt-20px">
            <label class="label-input">Prénom</label>
            <div>
                <input type="text" class="underline-input w-100" v-model="firstName" />
                <div class="error">{{ error.firstName }}</div>
            </div>
        </div>
        <div class="mt-20px">
            <label class="label-input">Nom</label>
            <div>
                <input type="text" class="underline-input w-100" v-model="lastName" />
                <div class="error">{{ error.lastName }}</div>
            </div>
        </div>
        <div class="mt-20px">
            <label class="label-input ">Courriel</label>
            <div>
                <input type="email" class="underline-input w-100" v-model="email" />
                <div class="error">{{ error.email }}</div>
            </div>
        </div>
        <div class="mt-20px d-flex space-around">
            <button class="button-grey" @click="saveMail()">Enregistrer</button>
        </div>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import axios from "@/axios-auth";
export default {
    data() {
        return {
            firstName: null,
            lastName: null,
            email: null,
            error: {
                firstName: null,
                lastName: null,
                email: null
            }
        }
    },
    components: {
        NavBar,
    },
    methods: {
        saveMail() {
            let submit = true
            this.error.firstname = null
            this.error.lastName = null
            this.error.email = null
            if (!this.firstName) {
                this.error.firstName = 'Vous devez entrer un prénom'
                submit = false
            }
            if (!this.lastName) {
                this.error.lastName = 'Vous devez entrer un nom'
                submit = false
            }
            if (!this.email) {
                this.error.email = 'Vous devez entrer un courriel'
                submit = false
            }
            if (submit) {
                if(this.$route.params.uuid){
                    axios.put('/v1/Email/'+this.$route.params.uuid, {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email
                    }, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                            "Content-Type": "application/json",
                        }
                    }).then((result) => {
                        if(result.status === 200){
                            this.$router.push('/configurer-les-destinataires')
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
                else{
                    axios.post('/v1/Email', {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email
                    }, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                            "Content-Type": "application/json",
                        }
                    }).then((result) => {
                        if(result.status === 200){
                            this.$router.push('/configurer-les-destinataires')
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }

        },
        getMail(){
            axios.get('/v1/email/'+this.$route.params.uuid,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
            "Content-Type": "application/json",
          },
        }).then((results)=>{
                this.firstName=results.data.firstName
                this.lastName=results.data.lastName
                this.email=results.data.email
            }).catch(error=>{
                console.log(error)
            })
        }
    },
    mounted(){
        this.getMail()
    }
}
</script>
