import axios from "axios";
import store from "./store";
import router from "./router"; // Assuming you are using Vue Router or similar

const instance = axios.create({
  //baseURL: "http://localhost:3000",
  baseURL: "https://enercycle-api.geoplatform.cloud",
});

instance.interceptors.request.use((config) => {
  store.commit("isLoading");
  return config;
});

instance.interceptors.response.use(
  (res) => {
    store.commit("isNotLoading");
    return res;
  },
  (error) => {
    // Handle error response
    store.commit("isNotLoading");

    if (error.response) {
      if (error.response.status === 403) {
        // Redirect to login
        router.push({ name: 'Home' });
      } 
      
      else if (error.code === 'ERR_NETWORK') {
        store.commit("internetConnection", false);
      } else {
        store.commit("internetConnection", true);
      }
      
      store.commit("thereIsErrorConnecting", 'Erreur de connexion');
    }
    
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

export default instance;
