<template>
  <div class="modal-container">
    <div class="popup">
      <div class="float-right" @click="$emit('close')">
        <CustomIcon name="close" ></CustomIcon>
      </div>
      <div class="point-number">Point {{ feature.properties.no }}</div>
      <div class="point-location">
        Localisation: {{ feature.properties.name }}
      </div>
      <div
        class="point-indication yellow-text-color"
        v-if="feature.properties.status === 1"
      >
        Le questionnaire n’a pas été complété ce jour
      </div>
      <div
        class="point-indication green-text-color"
        v-if="feature.properties.status === 2"
      >
        Le questionnaire a été rempli
      </div>
      <div
        class="point-indication red-text-color"
        v-if="feature.properties.status === 3"
      >
        Point innaccessible
      </div>
      <button class="button-grey w-100 mt-10px" @click="$emit('activate')">
        Remplir
      </button>
      <div class="mt-20px d-flex align-center error justify-end" @click="$emit('inaccessible')">  <CustomIcon name="not-accessible" ></CustomIcon> Point inaccessible</div>
    </div>
  </div>
</template>
<script>
import CustomIcon from "../CustomIcon.vue";
export default {
  props: {
    feature: Object,
  },
  components: {
    CustomIcon,
  },
  methods:{
  }
};
</script>
<style scoped>
.popup {
  background-color: white;
  padding: 12px;
  border: 2px solid #e8ae4a;
  max-width: 80%;
}
.point-number {
  color: #313030;
  /* Mobile/H3 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.point-location {
  color: #313030;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.point-indication {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
